
import React, { useEffect, useState } from 'react'
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import { videoListState, textListState, timeState, zoomState, canvasState, imageListState,audioListState, editState, elementState} from '../atoms';
import Dropzone from 'react-dropzone'
import Form from 'react-bootstrap/Form';
import { BsSearch } from 'react-icons/bs';
import Button from 'react-bootstrap/Button';

import { createClient } from 'pexels';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { TbPhoneIncoming } from 'react-icons/tb';
import cloneDeep from 'lodash/cloneDeep';
import { useTour } from '@reactour/tour'
import ProgressBar from 'react-bootstrap/ProgressBar';
import { BsUpload } from "react-icons/bs";






const Upload = () => {

  const [videoList, setVideoList] = useRecoilState(videoListState);
  const [imageList, setImageList] = useRecoilState(imageListState);
  const [audioList, setAudioList] = useRecoilState(audioListState);
  const [edit, setEdit] = useRecoilState(editState);
  const [element, setElement] = useRecoilState(elementState);
  const [isUploading, setIsUploading] = useState(false);





  const [zoom, setZoom] = useRecoilState(zoomState)
  const client = createClient('563492ad6f9170000100000182262b7b341e4afb906168f00ead247c');
  const [query, setQuery] = useState("landscape");
  const [pexelVideoList, setPexelVideoList] = useState(0);
  const [loadVideo, setLoadVideo] = useState(false)
  const [canvas, setCanvas] = useRecoilState(canvasState);
  const { isOpen, currentStep, steps, setIsOpen, setCurrentStep, setSteps } = useTour()
  const [isRemoving, setIsRemoving] = useState(false)
  const [progress, setProgress] = useState(0);
  const [loadImage, setLoadImage] = useState(false)




  useEffect(() => {
    searchVideos()
  }, [])


  useEffect(() => {
    if (isRemoving) {
      const interval = setInterval(() => {
        if (progress < 99) {
          setProgress(progress + 0.5)
        } else {
          setProgress(10)
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  })




  function setData(e) {
    if (e.type == "video/mp4" || e.type=="video/quicktime" || e.type === "video/x-ms-wmv") {
      setVideo(e)
    }

    if (e.type == "image/jpeg" || e.type == "image/png") {
      uploadImage(URL.createObjectURL(e))
    }

    if (e.type == "audio/mp3" || e.type == "audio/x-wav" || e.type == "audio/mpeg") {
      uploadSound(e)
    }
  }

  function uploadSound(sound) {
    var audio = document.createElement('audio');
    audio.src = URL.createObjectURL(sound)






    function setDuration() {
      let duration = audio.duration * 1000

      let al = cloneDeep(audioList)

      let starttime = 0
      if (audioList.length > 0) {
        starttime = audioList[audioList.length - 1].endTime
      }

   

     al.push({ id: audioList.length, "src": URL.createObjectURL(sound), file: sound, duration: duration, startTime: starttime, endTime: starttime + duration, trimmStart: 0.0, trimmEnd: 0.0 })



      setAudioList(al)
    }
    audio.addEventListener("loadedmetadata",
      setDuration

    );

  }

  function uploadImage(img) {

    setLoadImage(true)
    if (img !== null) {
      var image = new Image();
      image.src = img

      image.onload = function () {
        let newArr = [...imageList]
        let scaling = 1
        if (image.width > 400) {
          scaling = 400 / image.width
        }
        newArr.push({ "id": imageList.length, group: "image", "src": image.src, "x": 0, "y": 0, width: image.width * scaling, height: image.height * scaling, startTime: 0, endTime: 7000, visible: false })
        setImageList(newArr);
        setLoadImage(false)
      }



    }
    //setElement(imageList.length)
  }

  async function setVideo(e) {
    setIsUploading(true); // Start uploading
    var video = document.createElement('video');

    if (e.type === "video/quicktime" || e.type === "video/x-ms-wmv") { // .mov files have 'video/quicktime' MIME type
      let formData = new FormData();
      formData.append("video", e);
  
      try {
        const response = await axios.post(process.env.REACT_APP_BACKEND_URL + "/convertformat", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: 'blob', // Set responseType to 'blob'
        });
  
        // Convert the Blob (response.data) to a File object
        const convertedVideoFile = new File([response.data], "convertedVideo.mp4", { type: "video/mp4" });
  
        // Use the File object for further processing
        video.src = URL.createObjectURL(convertedVideoFile);
      } catch (error) {
        console.error("Error converting video format: ", error);
        return;
      }

    }else{
      video.src = URL.createObjectURL(e)
    }







    function setDuration() {
      let duration = video.duration * 1000

      let vl = cloneDeep(videoList)

      let starttime = 0
      if (videoList.length > 0) {
        starttime = videoList[videoList.length - 1].endTime
      }

      let c = cloneDeep(canvas)
      if (c.width < video.videoWidth) {
        c.width = video.videoWidth;
      }
      if (c.height < video.videoHeight) {
        c.height = video.videoHeight;
      }

      setCanvas(c)

      vl.push({ id: videoList.length, "src": URL.createObjectURL(e), file: e, duration: duration, "x": 0, "y": 0, startTime: starttime, endTime: starttime + duration, trimmStart: 0.0, trimmEnd: 0.0, width:video.videoWidth, height: video.videoHeight })



      setVideoList(vl)
      setEdit('video')
      setElement(videoList.length)
      setIsUploading(false); // Finish uploading
    }
    video.addEventListener("loadedmetadata",
      setDuration

    );


  }

  async function removeBackground() {
    let blob = await fetch(videoList[0].src).then(r => r.blob());
    var formData = new FormData();
    formData.append("video", blob, "video")
    if (isRemoving === false) {
      setIsRemoving(true)
      axios.post("https://backend.edit-videos-online.com/function/rbvideo", formData, { responseType: 'blob' }).then(response => {
        let vL = cloneDeep(videoList)
        vL[0].src = (URL.createObjectURL(response.data))
        vL[0].endTime = 5000;
        setVideoList(vL)
        setIsRemoving(false)

      })
    }
  }




  async function searchVideos() {
    client.videos.search({ query, per_page: 10 }).then(result =>
      setPexelVideoList(result.videos));



    //result.videos.map(video => await fetch(video.video_files[0].link).then(r => r.blob())));


  }

  function onClickVideo(video) {
    setLoadVideo(true)
    axios.get(video.video_files[0].link, { responseType: 'blob' }).then(r => { setVideo(r.data); setLoadVideo(false) })

  }

  useEffect(() => {
    setIsOpen(true)
    setSteps([
      { selector: '.uploadfield', content: 'New place 1' },
    ])
    setCurrentStep(1)
  }, [])

  return (
    <div style={{ height: "100%", padding: "2vh" }}>
      <div style={{ border: "20px", width: "100%", height: "100%", border: "2px dashed #2277ff", borderRadius: "5px" }}>


        <Dropzone accept={{ 'video/mp4': ['.mp4'], 'video/quicktime': ['.mov'], "video/x-ms-wmv": ['.wmv'], 'image/*': ['.jpeg', '.png'], 'audio/mp3': ['.mp3'], 'audio/wav': ['.wav'], 'audio/mpeg': ['.mpeg'] }} data-hj-allow onDrop={e => setData(e[0])} style={{ height: "100%" }}>
          {({ getRootProps, getInputProps }) => (
            <section data-hj-allow style={{ height: "100%" }}>
              <div data-hj-allow {...getRootProps()} style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", justifyItems: "center", alignItems: "center" }}>
                <input data-hj-allow {...getInputProps()} />
{isUploading?<Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Uploading...</span>
        </Spinner>:  <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                
                <div style={{}}><BsUpload color="#F7F7F7" size={40} /></div>
                <h4 style={{ color: "white" }}>Drag and drop here or</h4>
                <h4 style={{ color: "#2277ff" }}>upload media</h4></div>
                
           }
               
              </div>
            </section>
          )}
        </Dropzone>

      </div>

    </div>);
}

export default Upload;
import React, { useEffect } from 'react'
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';

import { videoListState, textListState, imageListState, timeState, zoomState, canvasState, scaleState, audioListState } from '../atoms';

import Video from './Video';
import Audio from './Audio';
import Canvas from './Canvas';
import StackedVideo from './StackedVideo';

function VideoView() {

  const [videoList, setVideoList] = useRecoilState(videoListState);
  const [audioList, setAudioList] = useRecoilState(audioListState);
  const [canvas, setCanvas] = useRecoilState(canvasState);
  const [scale, setScale] = useRecoilState(scaleState);




  const [zoom, setZoom] = useRecoilState(zoomState)







  function setVideo(e) {
    var video = document.createElement('video');
    video.src = URL.createObjectURL(e[0])


    function setDuration() {
      let duration = video.duration * 1000
      setVideoList([{ id: 0, "src": URL.createObjectURL(e[0]), file: e[0], duration: duration, "x": null, "y": null, startTime: 0, endTime: duration, trimmStart: 0.0, trimmEnd: 0.0 }])
      setZoom(10000)

      setCanvas(prevState => ({
        ...prevState,
        width: video.videoWidth,
        height: video.videoHeight
      }));
    }




    video.addEventListener("loadedmetadata",
      setDuration
    );
  }

  useEffect(() => {
    var canvasbg = document.getElementById("canvasbg");

    var scaleX = canvas.width / canvasbg.clientWidth
    var scaleY = canvas.height / canvasbg.clientHeight
    setScale([scaleX, scaleY])

  }, [canvas])





  return (
    <div id="canvasbg" style={{
      aspectRatio: `${canvas.width}/${canvas.height}`,
      maxHeight: "100%", maxWidth: "100%",
      backgroundColor: 'black',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative'
    }}>
      {audioList.map((key, index) =>
        <Audio obj={key} id={index}></Audio>
      )}
      

      <Video></Video>
      <Canvas style={{ position: "absolute" }}></Canvas>
    </div>

  );
}

export default VideoView
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";
import './index.css';



ReactDOM.render(

  
  <React.StrictMode>
    <Auth0Provider
      domain="dev-sv4a9m88.us.auth0.com"
      clientId="VRjdzH3EiLfQ3Js5KDldePuSljupf3vX"
      authorizationParams={{
        redirect_uri: `${window.location.origin}`,
        audience: "https://backend.edit-videos-online.com",
      }}

    >
      <App />
    </Auth0Provider>,

  </React.StrictMode>,
  document.getElementById('root')
);



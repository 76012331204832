import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

const StripeCheckout = () => {

  const { user } = useAuth0();


  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column', // Keeps headline and table in a column
      alignItems: 'center', // Center items horizontally
      justifyContent: 'center', // Center items vertically if needed
      height: '100vh', // Adjust based on your layout needs
      textAlign: 'center' // Ensure the headline is centered if it wraps
    }}>
      <h1 style={{ marginBottom: '20px' }}>Start your channel's growth journey now</h1>
      <h3 style={{ marginBottom: '20px' }}>One Time Payments only. No subscription required!</h3>

      {(

<stripe-pricing-table 
data-rewardful
style={{ width: '100%' }} 
pricing-table-id="prctbl_1PP1SGEB27wk31sW6Gry3y3p"
publishable-key="pk_live_51NXUMiEB27wk31sWLz1tr9uKknIwlrhum7Wi2BZEplt1hrk67MM5XPXe79jfu2rd1FmwSewPxEet1l8ds619XJeq00VzcpBzR7"
customer-email={user?.email}>
</stripe-pricing-table>
      )}
    </div>


  );
}

export default StripeCheckout;
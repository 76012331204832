import React, { useContext, useState, useEffect } from 'react'
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';

import { axiosInstance } from './axiosInstance';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { textListState } from '../atoms';
import { videoListState, editState } from '../atoms'
import Export from './Export';
import { BsFileFont, BsImage } from 'react-icons/bs';
import { ViewList } from 'react-bootstrap-icons';
import { MdOutlineSubtitles, MdTextFields, MdPhotoSizeSelectLarge } from "react-icons/md";
import { IoIosVideocam } from "react-icons/io";
import { BsUpload } from "react-icons/bs";
import { BsCameraVideo, BsSoundwave, BsMusicNoteBeamed } from "react-icons/bs";
import '../App.css';
import UserIcon from './UserIcon';
import { GiArtificialIntelligence } from "react-icons/gi";

import AvatarModal from './AvatarModal'
import AiVideoModal from './AiVideoModal';
import AuthButtons from './AuthButtons';


function Sidebar() {
  const [videoList, setVideoList] = useRecoilState(videoListState);
  const [textList, setTextList] = useRecoilState(textListState);
  const [edit, setEdit] = useRecoilState(editState);
  const [time, setTime] = useState(0);

  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false);

  const openAvatarModal = () => setIsAvatarModalOpen(true);
  const closeAvatarModal = () => setIsAvatarModalOpen(false);

  const [isAiModalOpen, setIsAiModalOpen] = useState(false);

  const openAiModal = () => {
    if(textList.length > 0){
      window.location.reload();
    }
    setIsAiModalOpen(true);
  }
  const closeAiModal = () => setIsAiModalOpen(false);


  useEffect(() => {
    const timerElement = document.getElementById('timer');
    if (timerElement) {
      const updateTime = (event) => {
        setTime(timerElement.currentTime * 1000);
      };

      timerElement.addEventListener('timeupdate', updateTime);

      // Clean up
      return () => {
        timerElement.removeEventListener('timeupdate', updateTime);
      };
    }
  }, []);

  useEffect(() => {

    if(isAiModalOpen){
      setEdit('AiModal')
    }else{
      setEdit('upload')
    }
  }, [isAiModalOpen])




  function newText() {
    //const newArr = JSON.parse(JSON.stringify(textList));

    let newArr = [...textList]
    newArr.push({ "id": textList.length, group: 0, "text": " AddedText ", "x": 0, "y": 0, startTime: time, endTime: time + 2000, color: "#aabbcc", backgroundColor: 'rgba(70,70,70,0.7)', size: 40, visible: false, font: "KomikaTitle", cornerRadius: 10 })
    setTextList(newArr);

  }

  function newVideo() {
    //const newArr = JSON.parse(JSON.stringify(textList));

    let newArr = [...ViewList]
    newArr.push({ "src": null, "x": null, "y": null })
    setVideoList(newArr);

  }



  function exportVideo() {

    const bodyFormData = new FormData();
    bodyFormData.append("texts", JSON.stringify(textList));
    bodyFormData.append("videos", JSON.stringify(videoList));

    axiosInstance({
      url: 'export',
      method: 'post',
      data: bodyFormData
    })
      .then(function (response) {
        // your action after success
      })
  }



  return (
    <div style={{
      backgroundColor: "#141725",
      height: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      borderRadius: "10px",
      padding: "1vh",
      rowGap: "10px",
      "@media (max-width: 576px)": {
        flexDirection: "row",
        overflowX: "auto",
        overflowY: "hidden",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }
    }}>      {/*
<div style = {{padding: "1vh", width:"100%"}}><Button style = {{minWidth:"100%"}} variant="primary"><BsUpload size ={25}/><p>Upload</p></Button></div>
      <div style = {{padding: "1vh",width:"100%"}}><Button onClick={() => newText()} style = {{minWidth:"100%"}}  variant="primary"><MdTextFields size ={25}/><p>Text</p></Button></div>
      <div style = {{padding: "1vh", width:"100%"}}><Button style = {{minWidth:"100%"}}  variant="primary"><MdOutlineSubtitles size ={25}/><p>Subtitles</p></Button></div>

*/}


      <div onClick={() => setEdit("upload")} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div style={{}}><BsUpload color="#F7F7F7" size={window.innerWidth <= 800 ? 20 : 40} /></div>
        <h6 style={{ color: "#F7F7F7", fontSize: window.innerWidth <= 800 ? '6px' : '16px' }}>Upload</h6>
      </div>
      <div onClick={() => setEdit("pexelvideos")} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div style={{}}><BsCameraVideo color="#F7F7F7" size={window.innerWidth <= 800 ? 20 : 40} /></div>
        <h6 style={{ color: "#F7F7F7", fontSize: window.innerWidth <= 800 ? '6px' : '16px' }}>Videos</h6>
      </div>

      <div onClick={() => setEdit("pexelimages")} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div style={{}}><BsImage color="#F7F7F7" size={window.innerWidth <= 800 ? 20 : 40} /></div>
        <h6 style={{ color: "#F7F7F7", fontSize: window.innerWidth <= 800 ? '6px' : '16px' }}>Images</h6>
      </div>

      <div onClick={() => setEdit("audio")} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div style={{}}><BsMusicNoteBeamed color="#F7F7F7" size={window.innerWidth <= 800 ? 20 : 40} /></div>
        <h6 style={{ color: "#F7F7F7", fontSize: window.innerWidth <= 800 ? '6px' : '16px' }}>Audio</h6>
      </div>


      <div onClick={() => newText()} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div style={{}}><MdTextFields color="#F7F7F7" size={window.innerWidth <= 800 ? 20 : 40} /></div>
        <h6 style={{ color: "#F7F7F7", fontSize: window.innerWidth <= 800 ? '6px' : '16px' }}>Text</h6>
      </div>

      <div onClick={() => setEdit("subtitles")} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div style={{}}><MdOutlineSubtitles color="#F7F7F7" size={window.innerWidth <= 800 ? 20 : 40} /></div>
        <h6 style={{ color: "#F7F7F7", fontSize: window.innerWidth <= 800 ? '6px' : '16px' }}>Subtitles</h6>
      </div>

      <div onClick={() => setEdit("settings")} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div style={{}}><MdPhotoSizeSelectLarge color="#F7F7F7" size={window.innerWidth <= 800 ? 20 : 40} /></div>
        <h6 style={{ color: "#F7F7F7", fontSize: window.innerWidth <= 800 ? '6px' : '16px' }}>Resolution</h6>
      </div>


      <div onClick={openAvatarModal} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        {isAvatarModalOpen && <div className="new-banner">New!</div>}
        <div><img style={{ borderRadius: "1vh" }} src="avatars.gif" alt="GIF" width={window.innerWidth <= 800 ? 20 : 45} /></div>
        <div style={{ zIndex: 10000 }}>
          <AvatarModal isOpen={isAvatarModalOpen} onRequestClose={closeAvatarModal}></AvatarModal>
        </div>
        <h6 style={{ color: "#F7F7F7", fontSize: window.innerWidth <= 800 ? '6px' : '16px' }}>AI Avatars</h6>
      </div>

    
      <UserIcon />

      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <h6 style={{ color: "#F7F7F7", fontSize: window.innerWidth <= 800 ? '6px' : '16px' }}></h6>
        <AuthButtons></AuthButtons>
      </div>

      <div onClick={() => openAiModal()} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div style={{}}><MdPhotoSizeSelectLarge color="#F7F7F7" size={window.innerWidth <= 800 ? 20 : 40} /></div>
        <h6 style={{ color: "#F7F7F7", fontSize: window.innerWidth <= 800 ? '6px' : '16px' }}>AI Videos</h6>
        <AiVideoModal isOpen={isAiModalOpen} onRequestClose={closeAiModal}></AiVideoModal>
      </div>
    </div>

  )
}

export default Sidebar
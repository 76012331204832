import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { InputGroup, FormControl, Button, Pagination, Spinner, Form, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { videoListState, editState, elementState, textListState, timeState, zoomState, canvasState } from '../atoms'; import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
} from 'recoil';
import cloneDeep from 'lodash/cloneDeep';


const PexelMedia = ({ mediaType }) => {
    const [videoList, setVideoList] = useRecoilState(videoListState);
    const [query, setQuery] = useState('nature');
    const [media, setMedia] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [zoom, setZoom] = useRecoilState(zoomState)
    const [canvas, setCanvas] = useRecoilState(canvasState);
    const [loadVideo, setLoadVideo] = useState(false)

    const [showModal, setShowModal] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [selectedQuality, setSelectedQuality] = useState(null);

    const [selectedQualityIndex, setSelectedQualityIndex] = useState(null);

    const [edit, setEdit] = useRecoilState(editState);
    const [element, setElement] = useRecoilState(elementState);

    const [error, setError] = useState(null);

    const handleSearch = async () => {
        try {
            const response = await axios.get(`https://api.pexels.com/videos/search?query=${query}&per_page=12&page=1`, {
                headers: {
                    Authorization: '563492ad6f9170000100000182262b7b341e4afb906168f00ead247c',
                },
            });
            setMedia(response.data.videos);
            setTotalPages(Math.ceil(response.data.total_results / 12));
            setCurrentPage(1);
        } catch (error) {
            console.error(error);
        }
    };

    const handlePageChange = async (pageNumber) => {
        try {
            const response = await axios.get(`https://api.pexels.com/videos/search?query=${query}&per_page=12&page=${pageNumber}`, {
                headers: {
                    Authorization: '563492ad6f9170000100000182262b7b341e4afb906168f00ead247c',
                },
            });
            setMedia(response.data.videos);
            setCurrentPage(pageNumber);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        handleSearch();
    }, []);

    const renderPaginationButtons = () => {
        const maxVisibleButtons = 5; // maximum number of visible pagination buttons
        const maxButtonsPerSide = Math.floor(maxVisibleButtons / 2); // maximum number of pagination buttons on each side of the current page button
        const startPage = Math.max(1, currentPage - maxButtonsPerSide);
        const endPage = Math.min(totalPages, currentPage + maxButtonsPerSide);
        const paginationButtons = [];

        for (let i = startPage; i <= endPage; i++) {
            paginationButtons.push(
                <Pagination.Item
                    key={i}
                    active={i === currentPage}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

        return paginationButtons;
    };

    async function setVideo(e) {
        var video = document.createElement('video');
        video.src = URL.createObjectURL(e)

        function setDuration() {
            let duration = video.duration * 1000

            let vl = cloneDeep(videoList)

            let starttime = 0
            if (videoList.length > 0) {
                starttime = videoList[videoList.length - 1].endTime
            }

            let c = cloneDeep(canvas)
            if (c.width < video.videoWidth) {
                c.width = video.videoWidth;
            }
            if (c.height < video.videoHeight) {
                c.height = video.videoHeight;
            }

            setCanvas(c)

            vl.push({ id: videoList.length, "src": URL.createObjectURL(e), file: e, duration: duration, "x": 0, "y": 0, startTime: starttime, endTime: starttime + duration, trimmStart: 0.0, trimmEnd: 0.0, width:video.videoWidth, height: video.videoHeight })



            setVideoList(vl)
            setEdit('video')
            setElement(videoList.length)

            setLoadVideo(false)
            setZoom(10000)
        }



        video.addEventListener("loadedmetadata",
            setDuration
        );


    }


    async function onClickVideo(video, qualityIndex) {
        setLoadVideo(true)

        const selectedQualityVideo = video.video_files[qualityIndex];
        axios.get(selectedQualityVideo.link, { responseType: 'blob' })
            .then(r => {
                setVideo(r.data);
                setLoadVideo(false);
                setError(null); // Clear any previous error
            })
            .catch(error => {
                console.error(error);
                setLoadVideo(false);
                if (error.response && error.response.status === 404) {
                    // Set the error message if a 404 error occurred
                    setError('The selected video quality is not available. Please select a different quality.');
                    setShowModal(true); // Show the modal again
                }
            });
    }



    const handleThumbnailClick = (video) => {
        setSelectedVideo(video);
        setSelectedQualityIndex(0); // Select the first quality by default
        setShowModal(true);
    }

    const handleModalClose = () => {
        setShowModal(false);
        setSelectedVideo(null);
        setSelectedQuality('');
    }

    const handleModalConfirm = () => {
        setShowModal(false);
        onClickVideo(selectedVideo, selectedQualityIndex);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };


    return (
        <div className="pexel-media-container d-flex flex-column align-items-center justify-content-center">
            <InputGroup className="search-container mb-3">
                <FormControl
                    placeholder={`Search for ${mediaType}`}
                    value={query}
                    onChange={(event) => setQuery(event.target.value)}
                    onKeyDown={handleKeyDown}
                    style={{ color: "gray" }}
                />
                <Button variant="primary" onClick={handleSearch}>Search</Button>
            </InputGroup>
            {loadVideo ? <Spinner animation="border" variant="primary" /> : <div>
                <div className="media-grid d-flex flex-column align-items-center">
                    {media.map((video) => (
                        <img style={{ width: "100%" }} key={video.id} src={video.image} className="m-2" onMouseDown={() => handleThumbnailClick(video)} />
                    ))}
                </div>
                <Modal show={showModal} onHide={handleModalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Select Video Quality</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display the error message if there is one */}
                        <Form.Control as="select" value={selectedQualityIndex} onChange={(event) => setSelectedQualityIndex(event.target.value)}>
                            {selectedVideo?.video_files.map((file, index) => (
                                <option key={index} value={index}>{`${file.quality.toUpperCase()} (${file.width}x${file.height})`}</option>
                            ))}
                        </Form.Control>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleModalConfirm}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>

                {totalPages > 1 && (
                    <Pagination className="pagination-container mt-3">
                        <Pagination.Prev
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                        />
                        {renderPaginationButtons()}
                        <Pagination.Next
                            disabled={currentPage === totalPages}
                            onClick={() => handlePageChange(currentPage + 1)}
                        />
                    </Pagination>
                )}
            </div>}
        </div>
    );

}

export default PexelMedia
import React, { Suspense } from 'react';
import { editState } from '../atoms';
import { useRecoilState } from 'recoil';

import TextEditor from './TextEditor';
import Upload from './Upload';
import Subtitles from './Subtitles';
import ImageEditor from './ImageEditor';
import Settings from './Settings';
import VideoEditor from './VideoEditor';
import AiVoice from './AiVoice';
import AudioEditor from './AudioEditor'

const PexelVideos = React.lazy(() => import('./PexelVideos'));
const PexelImages = React.lazy(() => import('./PexelImages'));


const Editor = () => {

  const [edit, setEdit] = useRecoilState(editState);








  return (
    <div style={{ backgroundColor: "#141725", width: "100%", height: "100%", borderRadius: "10px" }}>
      {edit != null ?
        {
          'text': <TextEditor />,
          'upload': <Upload />,
          'pexelvideos': <Suspense fallback={<div>Loading...</div>}>
            
              <PexelVideos />
            
          </Suspense>,
          'pexelimages': <Suspense fallback={<div>Loading...</div>}>
            
          <PexelImages />
        
      </Suspense>,
          'video': <VideoEditor />,
          'image': <ImageEditor />,
          'subtitles': <Subtitles />,
          'settings': <Settings />,
          'aivoice': <AiVoice />,
          'audio': <AudioEditor />


        }[edit]

        : <div></div>}

    </div>
  );
}

export default Editor;
import React, { useEffect, useState, useRef, useContext } from 'react'
import * as ReactDOM from 'react-dom';
import { imageListState, videoListState, textListState, playState, timeState, editState, zoomState, elementState, cursorUpdatedState, audioListState, zoomInState, zoomOutState } from '../atoms';
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';

import Timeline from 'react-visjs-timeline'
import TimelineStyle from './TimelineStyle.css'


import cloneDeep from 'lodash/cloneDeep';
import { elementType } from 'prop-types';


const TimePanel = (props) => {

  const [textList, setTextList] = useRecoilState(textListState);
  const [videoList, setVideoList] = useRecoilState(videoListState);
  const [imageList, setImageList] = useRecoilState(imageListState);
  const [audioList, setAudioList] = useRecoilState(audioListState);

  const [vlfororder, setVlfororder] = useState(null);



  const [play, setPlay] = useRecoilState(playState);
  const [edit, setEdit] = useRecoilState(editState);
  const [element, setElement] = useRecoilState(elementState);
  const [cursorUpdated, setCursorUpdated] = useRecoilState(cursorUpdatedState);

  const [zoomIn, setZoomIn] = useRecoilState(zoomInState);
  const [zoomOut, setZoomOut] = useRecoilState(zoomOutState);
  const [selectedItem, setSelectedItem] = useState(null);



  const [zoom, setZoom] = useState({ start: null, end: null });




  const timelineref = React.createRef();




  useEffect(() => {
    let intervalId = null
    intervalId = setInterval(() => {
      let time = document.getElementById('timer').currentTime * 1000
      const maxEndTime = videoList.reduce((max, video) => Math.max(max, video.endTime), 0);

      // Check if time is greater than the highest endTime
      /*if (time > maxEndTime) {
        const timerElement = document.getElementById('timer');
        if (timerElement) {
          timerElement.currentTime = 0;
        }
      }*/

      // Assuming timelineref is a ref to a timeline component
      timelineref.current.$el.setCustomTime(time, "currenttime");

    }, 20);

    return () => clearInterval(intervalId);
  });

  useEffect(() => {
    if (timelineref.current) {
      timelineref.current.$el.on('rangechanged', (properties) => {
        setZoom({ start: properties.start, end: properties.end });
      });
    }
  }, []);





  function convertAtomsToDataset() {
    var dataset = [];
    var groups = [];

    groups.push({ id: "video", content: "&#160" })
    for (let i = 0; i < videoList.length; i++) {
      let video = videoList[i]
      dataset.push({ id: video.id + textList.length, className: "video", start: video.startTime, end: video.endTime, content: "video", group: "video" })
    }

    if (imageList.length > 0) {
      groups.push({ id: "image", content: "&#160" })
    }
    for (let i = 0; i < imageList.length; i++) {
      let image = imageList[i]
      dataset.push({ id: image.id + textList.length + videoList.length, className: "image", start: image.startTime, end: image.endTime, content: "image", group: "image" })
    }

    groups.push({ id: "audio", content: "&#160" })
    for (let i = 0; i < audioList.length; i++) {
      let audio = audioList[i]
      dataset.push({ id: audio.id + textList.length + videoList.length + imageList.length, className: "audio", start: audio.startTime, end: audio.endTime, content: "audio", group: "audio" })
    }



    /*
    groups.push({ id: 0, content: "&#160" })
    for (let i = 0; i < textList.length; i++) {
      let text = textList[i]
      let newGroup = false;
      for(let e = 0; e < textList.length; e++){
        if(((textList[e].startTime > textList[i].startTime)  && (textList[e].startTime < textList[i].endTime) || (textList[e].endTime > textList[i].startTime)  && (textList[e].endTime < textList[i].endTime)) && ){
          newGroup = true;
        }
      }
      
      if(newGroup){
        groups.push({ id: groups.length-1, content: "&#160" })
      }
      dataset.push({ id: i, className: "text", start: text.startTime, end: text.endTime, content: text.text, group:groups.length-2})

    }
*/

    const hasGroupZero = textList.some(text => text.group === 0);


    if(hasGroupZero){
      groups.push({ id: 0, content: "&#160" })
    }
    

      groups.push({ id: 1, content: "&#160" })
    


    for (let i = 0; i < textList.length; i++) {
      let text = textList[i]
      groups.push({ id: groups.length - 1, content: "&#160" })
      dataset.push({ id: i, className: "text", start: text.startTime, end: text.endTime, content: text.text, group: text.group })

    }



    timelineref.current.$el.setItems(dataset)
    timelineref.current.$el.setGroups(groups)
  }

  function changestartinatomtext(id, newstart, newend, group) {

    let newList = [...textList].map((item) => {
      let newData = { ...item }
      if (item.id === id) {
        newData.startTime = newstart;
        newData.endTime = newend;
        newData.group = group;
        return newData;
      }
      else return item;
    });
    setTextList(newList);
  }

  function changestartinatomaudio(id, newstart, newend, group) {
    let newList = [...audioList].map((item) => {
      let newData = { ...item }
      if (item.id === id - textList.length - videoList.length - imageList.length) {
        newData.startTime = newstart;
        newData.endTime = newend;
        newData.group = group;
        return newData;
      }
      else return item;
    });
    setAudioList(newList);
  }

  function changestartinatomimage(id, newstart, newend, group) {
    let newList = [...imageList].map((item) => {
      let newData = { ...item }
      if (item.id === id - textList.length - videoList.length) {
        newData.startTime = newstart;
        newData.endTime = newend;
        newData.group = group;
        return newData;
      }
      else return item;
    });
    setImageList(newList);
  }

  function deletetextinatom(id) {
    setTextList((textList) =>
      textList
        .filter((_, index) => index !== id)
        .map((item, index) => ({ ...item, id: index }))
    );
  }

  function deleteimageinatom(id) {
    id = id - textList.length - videoList.length
    setImageList((imageList) => imageList.filter((value, index) => value !== imageList[id]));
  }

  function deleteaudioinatom(id) {
    id = id - textList.length - videoList.length - imageList.length;
    setAudioList((audioList) =>
      audioList
        .filter((_, index) => index !== id)
        .map((item, index) => ({ ...item, id: index }))
    );
  }

  function deletevideoinatom(id) {
    id = id - textList.length;

    // Filter out the video with the specified ID
    const filteredList = videoList.filter((_, index) => index !== id);

    // Initialize a variable to keep track of the previous video's endTime
    let prevEndTime = 0;

    // Update the time values of the remaining videos
    const updatedList = filteredList.map((video, index) => {
      // Calculate the duration of the current video
      const duration = video.endTime - video.startTime;

      // The startTime of the current video is the endTime of the previous video
      const newStartTime = prevEndTime;

      // The endTime of the current video is its startTime plus its duration
      const newEndTime = newStartTime + duration;

      // Update the prevEndTime for the next iteration
      prevEndTime = newEndTime;

      // Return the updated video object
      return { ...video, id: index, startTime: newStartTime, endTime: newEndTime };
    });

    setVideoList(updatedList);
  }





  async function changestartinatomvideo(id, newstart, newend) {
    id = id - textList.length - imageList.length
    const vl = cloneDeep(videoList);

    vl[id].startTime = newstart
    vl[id].endTime = newend


    //change range fully
    /* if ((Math.round(vl[id].endTime) - Math.round(vl[id].startTime)) === (Math.round(newend) - Math.round(newstart))) {
       vl[id].startTime = newstart;
       vl[id].endTime = newend;
 
     }*/

    /* if(vl[id].startTime < vl[id-1].endTime){
     }*/
    /*
        //Change range start
        if ((Math.round(vl[0].startTime) !== newstart) && (Math.round(vl[0].endTime) === newend)) {
          let relativechange = newstart - vl[0].startTime
          if (vl[0].trimmStart += relativechange > 0) {
            vl[0].trimmStart += relativechange
          }
          vl[0].startTime = 0;
          vl[0].endTime = vl[0].endTime - relativechange
    
        }
    
        //Change range end
        if (Math.round(vl[0].endTime) !== newend && (Math.round(vl[0].startTime) == newstart)) {
          if (newend - newstart < vl[0].duration) {
            let relativechange = vl[0].endTime - newend
            if (vl[0].trimmEnd += relativechange > 0) {
              vl[0].trimmEnd += relativechange
              vl[0].endTime = newend
            }
          }
        }*/



    setVideoList(vl)
  }







  useEffect(() => {

    //if(document.getElementsByClassName("vis-vertical-scroll") != null){
    //scrollTop = document.getElementsByClassName("vis-vertical-scroll")[0].scrollTop;
    //}*/
    /*let timeouttwo = setTimeout(() => {
      if(document.getElementsByClassName("vis-vertical-scroll") != null){
        scrollTop = document.getElementsByClassName("vis-vertical-scroll")[0].scrollTop;
      }
    }, 0);*/


    convertAtomsToDataset();


    // Restore the saved time and zoom level

    //setZoom(videoList[0].duration)

    if (timelineref.current && zoom.start && zoom.end) {
      timelineref.current.$el.setWindow(zoom.start, zoom.end);
    }

  }, [imageList, videoList, textList, play, edit, zoom, element, cursorUpdated, audioList, timelineref]);


  useEffect(() => {
    let clonedVideos = cloneDeep(videoList);
    let secondListIndices = new Set();

    // Iterate through the videos
    for (let i = 0; i < clonedVideos.length; i++) {
      if (secondListIndices.has(i)) {
        continue;
      }

      for (let j = i + 1; j < clonedVideos.length; j++) {
        if (clonedVideos[i].startTime < clonedVideos[j].startTime && clonedVideos[j].startTime < clonedVideos[i].endTime) {
          secondListIndices.add(j);
        }
      }
    }

    // Set 'stacked' property for videos
    clonedVideos.forEach((video, index) => {
      video.stacked = secondListIndices.has(index);
    });

    // Update the state with the modified list
    setVideoList(clonedVideos);

    console.log(videoList)

  }, [play]);




  useEffect(() => {
    timelineref.current.$el.zoomIn(1)
  }, [zoomIn]);

  useEffect(() => {
    timelineref.current.$el.zoomOut(1)
  }, [zoomOut]);




  useEffect(() => {





    timelineref.current.$el.addCustomTime(0, "currenttime")

    timelineref.current.$el.on('timechange', function (properties) {
      setCursorUpdated((e) => e + 1)
      document.getElementById("timer").currentTime = properties.time.getTime() / 1000;
    });



    //let id = properties.items[0]
    //let type = this.itemSet.items[id].data.className

  }, [])

  const textListRef = useRef(textList);
  const videoListRef = useRef(videoList);
  const imageListRef = useRef(imageList);


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        if (selectedItem && edit) {
          switch (edit) {
            case "text":
              deletetextinatom(selectedItem);
              break;
            case "image":
              deleteimageinatom(selectedItem);
              break;
            case "video":
              deletevideoinatom(selectedItem);
              break;
            case "audio":
              deleteaudioinatom(selectedItem);
              break;
            default:
              break;
          }
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedItem, edit]);

  useEffect(() => {
    textListRef.current = textList;
    videoListRef.current = videoList;
    imageListRef.current = imageList;
  }, [textList, videoList, imageList]); //

  useEffect(() => {
    timelineref.current.$el.on('select', function (properties) {
      let id = properties.items[0]
      let type = this.itemSet.items[id].data.className

      setEdit(type)

      setSelectedItem(id);


      if (type === "text") {
        setElement(id)

      }

      if (type === "image") {
        setElement(id - textListRef.current.length - videoListRef.current.length)

      }

      if (type === "video") {
        setElement(id - textListRef.current.length)

      }



    });

  }, [])


  useEffect(() => {
    convertAtomsToDataset();



  }, [videoList])

  /*useEffect(() => {
    timelineref.current.$el.setCustomTime(time, "currenttime")
  }, [time])*/



  var items = [
    {
      start: new Date(2010, 7, 15),
      end: new Date(2010, 8, 2),  // end is optional
      content: 'Trajectory A'
      // Optional: fields 'id', 'type', 'group', 'className', 'style'
    }
    // more items...
  ];



  function caluc(date) {
    return "test"
  }

  const ItemTemplate = (props) => (
    <div style={{ paddingLeft: "10px" }}>
      {props.item.content === "video" ?
        <div style={{ display: "flex" }}>
          {/*videoList[-1].previewImages !== undefined ? videoList[props.item.id - textList.length - imageList.length].previewImages.map((src) => <img style={{ height: props.element.height }} src={src} />) : <h1>Video</h1>*/}
          <p>Video</p>
        </div>
        : props.item.content}

    </div>
  )

  useEffect(() => {
    convertAtomsToDataset()
    convertAtomsToDataset()
    convertAtomsToDataset()
  }, [])



  var lastVideoEndTime = 15000
  if (videoList && videoList.length > 0 && videoList[videoList.length - 1].endTime) {
    lastVideoEndTime = videoList[videoList.length - 1].endTime
  }

  const options = {
    min: 0,
    start: 0,
    end: lastVideoEndTime,
    //max:20000,
    snap: null,
    width: '100%',
    orientation: "top",
    stack: true,
    stackSubgroups: true,
    showMajorLabels: false,
    showCurrentTime: true,
    margin: {
      item: {
        horizontal: 0
      }
    },
    order: (a, b) => {
      // Assuming 'a' and 'b' are items with 'start' properties
      let obj = videoList.find(x => x.id === a.id)
      if (obj) {
        //setVlfororder(obj.id)
      }
      return Math.abs(a.start) - Math.abs(b.start)
    },

    //type: 'background',

    //zoomable: false,
    itemsAlwaysDraggable: {
      item: true,
      range: true,
    },
    template: (item, element, data) => {
      ReactDOM.render(<ItemTemplate item={item} element={element} />, element)
    }
    ,
    maxHeight: "32vh",
    format: {
      minorLabels: function caluc(date, scale, step) {

        return date.valueOf() / 1000
      },
      majorLabels: function calcmajorLabels(date, scale, step) {
        return "test"
      }

    },
    editable: {
      remove: true,
      updateGroup: false,
      updateTime: true
    },

    onMove: function (item, callback) {


      if (item.className == "image") {
        changestartinatomimage(item.id, item.start.getTime(), item.end.getTime(), item.group)
      }

      if (item.className == "text") {
        changestartinatomtext(item.id, item.start.getTime(), item.end.getTime(), item.group)
      }

      if (item.className == "video") {
        changestartinatomvideo(item.id, item.start.getTime(), item.end.getTime())
      }

      if (item.className == "audio") {
        changestartinatomaudio(item.id, item.start.getTime(), item.end.getTime())
      }

    },



    onRemove: function (item, callback) {
      deletetextinatom(item.id)
      deleteimageinatom(item.id)
      deletevideoinatom(item.id)
      deleteaudioinatom(item.id)
    }

  }


  /*if(zoomIn !== 0 || zoomOut !==0){
    options.end = null;
  }*/




  return (
    <div id="timepanel">
      <div style={{ position: 'relative' }}>
        <div style={{ display: "flex" }}>


          {/*<Slider waria-label="Volume" value={zoom} onChange={e => changeZoom(e.target.value)} onChangeCommitted={() => resetTimeline()} min={1000} max={100000} style = {{margin:"1.5vh", width:"30%"}}/>*/}
        </div>
        <div style={{ overflow: "scroll" }}>
          <div style={{ overflow: "scroll" }}>


            <Timeline
              ref={timelineref}
              options={options}
              animation={false}
            />

          </div>
        </div>
      </div>

    </div>


  )
}

export default TimePanel
import { audioListState, playState } from "../atoms";
import React, { useEffect, useRef, useState, useContext } from 'react'
import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
} from 'recoil';

const Audio = (props) => {
    const inputRef = useRef();

    const [play, setPlay] = useRecoilState(playState);
    const [time, setTime] = useState(0);
    const [audioList, setAudioList] = useRecoilState(audioListState);


    function shouldaudioberendered() {
        if (time > props.obj.startTime && time < props.obj.endTime) {
            return false
        } else {
            return true
        }
    }

    useEffect(() => {
        document.getElementById('timer').addEventListener('timeupdate', (event) => {
            setTime(document.getElementById('timer').currentTime * 1000)
        });
    }, [])

    useEffect(() => {
        let starttime = time - audioList[0].startTime
        if (starttime < 0) {
            starttime = 0
        }
        inputRef.current.currentTime = (starttime + audioList[0].trimmStart) / 1000

    }, [audioList])

    useEffect(() => {
        if (time > audioList[props.obj.id].startTime && time < audioList[props.obj.id].endTime && play) {
         
            inputRef.current.currentTime = (time - audioList[props.obj.id].startTime) / 1000
            inputRef.current.play()
        } else {
            inputRef.current.pause()
        }
        

    }, [time, play])

   

    return (
        <React.Fragment >
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                <audio muted={shouldaudioberendered()} id={props.obj.id} src={audioList[props.obj.id].src} ref={inputRef}>
                </audio>
            </div>

        </React.Fragment>);
}

export default Audio;

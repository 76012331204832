import React, { useEffect, useState } from 'react';
import './App.css';
import { useAuth0 } from "@auth0/auth0-react";
import { RecoilRoot } from 'recoil';
import VideoEditorWrapper from './components/VideoEditorWrapper';
import StripeCheckout from './components/StripeCheckout';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from 'react-router-dom';

function App() {
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true); // New loading state for fetching subscription
  const { user, isAuthenticated, loginWithRedirect, getAccessTokenSilently, isLoading } = useAuth0();

  /*useEffect(() =>{
    if(!isLoading && !isAuthenticated){
      loginWithRedirect({ screen_hint: 'signup' })
    }
  }, [isLoading, isAuthenticated, loginWithRedirect])*/


  /*if (!isAuthenticated || isLoading) { // Check loading state along with isAuthenticated
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }*/

  return (
    <div className="app-root">
      <RecoilRoot>

        <BrowserRouter>
          <Routes>
            <Route path="/" element={<VideoEditorWrapper />} />
            <Route path="/checkout" element={<StripeCheckout />} />
            {/* You can add more routes as needed */}
          </Routes>
        </BrowserRouter>
        {/*subscription === "Hobby" || subscription === "Professional" || subscription === "Agency" ? (
          <VideoEditorWrapper />
        ) : (
          <StripeCheckout />
        )*/}
      </RecoilRoot>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { InputGroup, FormControl, Button, Pagination } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { editState, imageListState, elementState } from '../atoms';
import Spinner from 'react-bootstrap/Spinner';

import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
} from 'recoil';

const PexelImages = () => {
    const [query, setQuery] = useState('ball');
    const [images, setImages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loadImage, setLoadImage] = useState(false);
    const [imageList, setImageList] = useRecoilState(imageListState);
    const [element, setElement] = useRecoilState(elementState);
    const [edit, setEdit] = useRecoilState(editState);


    const handleSearch = async () => {
        try {
            const response = await axios.get(`https://api.pexels.com/v1/search?query=${query}&per_page=12&page=1`, {
                headers: {
                    Authorization: '563492ad6f9170000100000182262b7b341e4afb906168f00ead247c',
                },
            });
            setImages(response.data.photos);
            setTotalPages(Math.ceil(response.data.total_results / 12));
            setCurrentPage(1);
        } catch (error) {
            console.error(error);
        }
    };

    const handlePageChange = async (pageNumber) => {
        try {
            const response = await axios.get(`https://api.pexels.com/v1/search?query=${query}&per_page=12&page=${pageNumber}`, {
                headers: {
                    Authorization: '563492ad6f9170000100000182262b7b341e4afb906168f00ead247c',
                },
            });
            setImages(response.data.photos);
            setCurrentPage(pageNumber);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        handleSearch();
    }, []);

    const renderPaginationButtons = () => {
        const maxVisibleButtons = 5; // maximum number of visible pagination buttons
        const maxButtonsPerSide = Math.floor(maxVisibleButtons / 2); // maximum number of pagination buttons on each side of the current page button
        const startPage = Math.max(1, currentPage - maxButtonsPerSide);
        const endPage = Math.min(totalPages, currentPage + maxButtonsPerSide);
        const paginationButtons = [];

        for (let i = startPage; i <= endPage; i++) {
            paginationButtons.push(
                <Pagination.Item
                    key={i}
                    active={i === currentPage}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

        return paginationButtons;
    };

    function uploadImage(img) {

        setLoadImage(true)
        if (img !== null) {
            var image = new Image();
            image.src = img

            image.onload = function () {
                let newArr = [...imageList]
                let scaling = 1
                if (image.width > 400) {
                    scaling = 400 / image.width
                }
                newArr.push({ "id": imageList.length, group: "image", "src": image.src, "x": 0, "y": 0, width: image.width * scaling, height: image.height * scaling, startTime: 0, endTime: 7000, visible: false })
                setImageList(newArr);
                setLoadImage(false)
            }



        }
        setEdit("image")
        setElement(imageList.length)
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };
    


    return (
        <div >
             {loadImage ? <div><Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                /><p style={{ color: "white" }}>Loading selected image into editor...</p></div> :
           
            <div>
                <InputGroup>
                    <FormControl
                        placeholder="Search for images"
                        value={query}
                        onChange={(event) => setQuery(event.target.value)}
                        style={{color:"gray"}}
                        onKeyDown={handleKeyDown} // Add this line

                    />
                    <Button variant="primary" onClick={handleSearch}>Search</Button>
                </InputGroup>
                <div className="image-grid d-flex flex-column align-items-center">
                    {images.map((image) => (
                        <img onMouseDown={() => uploadImage(image.src.original)} key={image.id} src={image.src.medium} alt={image.photographer} rounded className="m-2" />
                    ))}
                </div>
                {totalPages > 1 && (
                    <Pagination className="pagination-container mt-3">
                        <Pagination.Prev
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                        />
                        {renderPaginationButtons()}
                        <Pagination.Next
                            disabled={currentPage === totalPages}
                            onClick={() => handlePageChange(currentPage + 1)}
                        />
                    </Pagination>
                )}

            </div>}
        </div>



    );
};

export default PexelImages;

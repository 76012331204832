import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';

let buttonStyle = false ? { marginLeft: "0.5vh", fontSize: "5px" } : { marginLeft: "1vh" };


function AuthButtons() {
    const { loginWithPopup, logout, isAuthenticated } = useAuth0();

    return (
        <div>
            {!isAuthenticated && (


                <Button  variant="success" onClick={() => loginWithPopup()}>
                    Login
                </Button>
            )}

            {isAuthenticated && (
                <Button style={buttonStyle} variant="danger" onClick={() => logout({ returnTo: window.location.origin })}>
                    Log Out
                </Button>

            )}
        </div>
    );
}

export default AuthButtons;

import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Button, Menu, MenuItem } from '@mui/material';

function UserIcon() {
  const { user, logout, isAuthenticated } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
    handleClose(); // Ensure the menu is closed after logging out
  };

  const handleManageUser = () => {
    window.open("https://billing.stripe.com/p/login/28odUV2Ke8wv8o04gg", "_blank", "noopener,noreferrer");
    handleClose(); // Ensure the menu is closed after logging out
  };

  if (!isAuthenticated) {
    return null; // Return nothing if the user isn't authenticated
  }

  return (
    <div>
      <Button
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        
        <Avatar src={user.picture || ""} alt={user.name}>{!user.picture && user.name.charAt(0)}</Avatar>
      </Button>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleManageUser}>Manage User</MenuItem> {/* New menu item for managing the user */}
        <MenuItem onClick={handleLogout}>Log Out</MenuItem>
      </Menu>
    </div>
  );
}

export default UserIcon;

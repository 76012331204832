import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";


Modal.setAppElement('#root');

function PaymentModal({ isOpen, onRequestClose }) {

  const {getAccessTokenSilently} = useAuth0();



  const handlePurchase = async (details, data, amount) => {

    try {
      const accessToken = await getAccessTokenSilently();

      // Define the endpoint for adding credits
      const addCreditsEndpoint = `${process.env.REACT_APP_BACKEND_URL}/addCredits`;

      // Send a POST request to your Flask endpoint with the accessToken
      const response = await axios.post(addCreditsEndpoint, {
        creditsToAdd: amount// Send the creditAmount as JSON in the body
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Include the authorization header
          'Content-Type': 'application/json' // Set the content type as JSON
        },
      });

      console.log("Credits added successfully:", response.data);
      // Update the credits state to reflect the new balance
      onRequestClose()
    } catch (e) {
      console.error("Error adding credits:", e);
    }
  };


  const planContents = [
    {
      header: "10 Credits",
      price: 9,
      amount: 10,
      features: [
        "10 Videos",
        "AI Background Videos",
        "AI Voiceover",
        "AI Subtitles",
        "High resolution download"
      ],
      outline: true
    },
    {
      header: "30 Credits",
      price: 29,
      amount: 30,
      features: [
        "30 Videos",
        "AI Background Videos",
        "AI Voiceover",
        "AI Subtitles",
        "High resolution download"
      ],
      outline: true
    },
    {
      header: "70 Credits",
      price: 69,
      amount: 70,
      features: [
        "10 Videos",
        "AI Background Videos",
        "AI Voiceover",
        "AI Subtitles",
        "High resolution download"
      ],
      outline: true
    }
  ];

  const Plan = props => {
    return (
      <div className="card mb-4 shadow-sm">
        <div className="card-header">
          <h4 className="my-0 font-weight-normal">
            {props.amount} Credits
          </h4>
        </div>
        <div className="card-body">
          <h1 className="card-title pricing-card-title">
            {`$${props.price}`}
          </h1>
          <ul className="list-unstyled mt-3 mb-4">
            {props.features.map((feature, i) => (
              <li key={i}>{feature}</li>
            ))}
          </ul>
          <PayPalScriptProvider options={{ "client-id": "AVuc6q8xAShMuEaA0iscawLZZTAdsJtL8dotJaTx6f66GipomL9LUC2WI-nJEhLXTRwD5PEUKCq7GG1d" }}>
            <PayPalButtons
              key={props.amount}
              style={{ layout: 'horizontal' }}
              createOrder={(data, actions) => {
                return actions.order.create({
                  purchase_units: [{
                    amount: {
                      value: props.price, // Update this based on your pricing
                    },
                  }],
                });
              }}
              onApprove={(data, actions) => {
                return actions.order.capture().then(details => {
                  console.log(props)
                  handlePurchase(details, data, props.amount);
                });
              }}
            />
          </PayPalScriptProvider>
        </div>
      </div>
    );
  };

    const plans = planContents.map((obj, i) => {
      return (
        <Plan
          key={obj.header}
          header={obj.header}
          price={obj.price}
          features={obj.features}
          buttonLabel={obj.buttonLabel}
          outline={obj.outline}
          amount={obj.amount}
        />
      );
    });
  

    return (
      <div>
        <Modal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          contentLabel="Payment Modal"
          style={{
            content: {
              borderRadius: '15px',
              border: '1px solid #ffffff',
              boxShadow: '0px 0px 10px 1px #0a8dff',
              backgroundColor: '#181818',
              color: '#ffffff',
              padding: '20px', // Ensure there's padding for aesthetics
              display: 'flex',
              flexDirection: 'column', // This makes the content grow vertically
              height: '90vh', // Adjust based on your modal size preferences
            },
          }}
        >
          <h2>Buy Credits</h2>
          <div>
            <div style={{ color:"black", display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
              {plans}
            </div>

          </div>
          
        </Modal>
      </div>
    );
  };

  export default PaymentModal;

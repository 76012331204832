import React, { useState } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';

import ToggleButton from 'react-bootstrap/ToggleButton';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { BiSearchAlt2 } from "react-icons/bi";
import PexelImages from './PexelImages';
import PexelVideos from './PexelVideos.js';

function Pexel(props) {

    const [radioValue, setRadioValue] = useState('videos');
    const [query, setQuery] = useState("landscape");

    const radios = [
        { name: 'videos', value: 'videos' },
        { name: 'images', value: 'images' },
    ];








    return (
        <div style={{ backgroundColor: "#141725", display: "flex", alignItems: "center", flexDirection: "column", padding: "1vh" }}>

            <div >
                <ButtonGroup className="mb-2">
                    {radios.map((radio, idx) => (
                        <ToggleButton
                            key={idx}
                            id={`radio-${idx}`}
                            type="radio"
                            variant="dark"
                            name="radio"
                            value={radio.value}
                            checked={radioValue === radio.value}
                            onChange={(e) => setRadioValue(e.currentTarget.value)}
                        >
                            {radio.name}
                        </ToggleButton>
                    ))}
                </ButtonGroup>


            </div>



            {radioValue != null ?
                {
                    'images': <PexelImages />,
                    'videos': <PexelVideos />,

                }[radioValue]

                : <div></div>}


        </div>
    );
}

export default Pexel;
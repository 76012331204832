import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useRecoilState } from 'recoil';
import { Button, Form, Container, Row, Col, Dropdown } from 'react-bootstrap';
import axios from "axios";
import cloneDeep from 'lodash/cloneDeep';
import { videoListState, textListState, timeState, zoomState, canvasState, imageListState, audioListState, editState, elementState } from '../atoms';
import Spinner from 'react-bootstrap/Spinner';

import { ProgressBar } from 'react-bootstrap';
import { useAuth0 } from "@auth0/auth0-react";
import FakeProgress from './FakeProgress';
import RangeSlider from 'react-bootstrap-range-slider';
import PaymentModal from './PaymentModal';
import UserIcon from './UserIcon';
import YouTubeAuth from './YoutubeAuth';



Modal.setAppElement('#root');

function AiVideoModal({ isOpen, onRequestClose }) {
  const [description, setDescription] = useState();
  const [videos, setVideos] = useState();
  const [videoScript, setVideoScript] = useState('');
  const [videoScriptLoading, setVideoScripLoading] = useState(false);
  const [videoGenerationLoading, setVideoGenerationLoading] = useState(false);
  const [paymentModel, setPaymentModel] = useState(false)

  const [oldVideos, setOldVideos] = useState()

  const [audioList, setAudioList] = useRecoilState(audioListState);

  const [subtitles, setSubtitles] = useState(); // Add this state to hold subtitles

  const [textList, setTextList] = useRecoilState(textListState);
  const [videoList, setVideoList] = useRecoilState(videoListState);

  const [title, setTitle] = useState();

  const [selectedTitles, setSelectedTitles] = useState([]);
  const [selectedNiche, setSelectedNiche] = useState();

  const [currentStep, setCurrentStep] = useState(0);

  const basePath = process.env.PUBLIC_URL + '/voices/';

  const voiceFileNumbers = [7, 11, 15, 17, 19, 25, 29, 31, 35, 41, 42, 45, 49, 51, 54, 57, 62, 64, 66, 73, 75, 94];

  const [loadingVideoTitle, setLoadingVideoTitle] = useState(null);


  const [credits, setCredits] = useState(3);

  const { user, isAuthenticated, getAccessTokenSilently, loginWithPopup, accessToken } = useAuth0();


  




  const [selectedVoice, setSelectedVoice] = useState("alloy"); // Default to the first voice
  const [audioElement, setAudioElement] = useState(new Audio());


  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  useEffect(() => {
    if (currentStep === 2) {
      generateScript()
    }

    if (currentStep === 3) {
      if (credits > 0) {
        generateVideo()
      } else {
        setCurrentStep(2)
        setPaymentModel(true)
      }

    }

    if (currentStep === 0) {
      getVideos()
    }
  }, [currentStep])




  const getCredits = async () => {

    try {
      const accessToken = await getAccessTokenSilently();

      // Call your Flask endpoint with the accessToken
      const creditsEndpoint = `${process.env.REACT_APP_BACKEND_URL}/getCredits`; // Adjust with your actual Flask endpoint
      const response = await axios.post(creditsEndpoint, {}, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setCredits(response.data.credits);
    } catch (e) {
      console.error(e);
    }
  };


  const decreaseCredits = async () => {


    try {
      const accessToken = await getAccessTokenSilently();

      // Call your Flask endpoint with the accessToken
      const endpoint = `${process.env.REACT_APP_BACKEND_URL}/decreaseCredits`; // Adjust with your actual Flask endpoint
      const response = await axios.post(endpoint, {}, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      getCredits()

    } catch (e) {
      console.error(e);
    }
  };

  const getVideos = async () => {
    const accessToken = await getAccessTokenSilently();

    // Call your Flask endpoint with the accessToken
    const videoEndpoint = `${process.env.REACT_APP_BACKEND_URL}/getVideos`; // Adjust with your actual Flask endpoint
    const response = await axios.post(videoEndpoint, {}, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    setOldVideos(response.data)
  }

  useEffect(() => {
    const getUserMetadata = async () => {

      try {
        const accessToken = await getAccessTokenSilently();

        // Call your Flask endpoint with the accessToken
        const userEndpoint = `${process.env.REACT_APP_BACKEND_URL}/createUser`; // Adjust with your actual Flask endpoint
        const response = await axios.post(userEndpoint, {}, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        console.log("User creation or update response:", response.data);
      } catch (e) {
        console.error(e);
      }
    };

    if (isAuthenticated) {
      getUserMetadata();

    }
  }, [isAuthenticated, getAccessTokenSilently]);

  const generateScript = async () => {
    try {
      setVideoScripLoading(true)
      const scriptResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/generateScript`, { description });

      setVideoScript(scriptResponse.data.Story || scriptResponse.data.story);

      setTitle(scriptResponse.data.Title || scriptResponse.data.title)

      setVideoScripLoading(false)
    } catch {

    }
  }

  const generateVideo = async (duration, segments) => {
    setVideoGenerationLoading(true)
    const accessToken = await getAccessTokenSilently();
    const videosResponse = null;
    console.log(selectedVoice)
    try {
      videosResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/generateVideo`,
        {
          script: videoScript,
          voice: selectedVoice,
          title: title
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          }
        }
      );

      // Handle success (videosResponse contains the successful response)
      console.log(videosResponse.data);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        // If the server returns a 403 Forbidden response, show an alert
        alert("You've reached your video creation limit for today.");
      }
    }

    const videos = null;
    const fetchedVideos = [];

    try {

      videos = videosResponse?.data; // Assuming the response has a keywords property that is a comma-separated string

      let i = 0;
      for (const video of videos) {

        fetchedVideos.push({
          id: i,
          url: process.env.REACT_APP_BACKEND_URL + "/static/" + video // Assuming we want the link to the video file
        });
        i++;

      }
    } catch (fetchError) {
    }

    //setVideos(fetchedVideos);
    setCurrentStep(0)
  }


  const addSelectedVideosToEditor = async () => {
    let newVideoList = [];
    for (const video of videos) {
      let videoURL = `${process.env.REACT_APP_BACKEND_URL}/static/` + video
      try {
        const response = await axios.get(videoURL, { responseType: 'blob' });
        const videoBlob = response.data;

        const videoFile = new File([videoBlob], "downloadedVideo.mp4", { type: "video/mp4" });

        var videoElement = document.createElement('video');
        videoElement.src = URL.createObjectURL(videoFile);


        await new Promise((resolve) => {
          videoElement.onloadedmetadata = () => {
            let duration = videoElement.duration * 1000;

            let starttime = newVideoList.length > 0 ? newVideoList[newVideoList.length - 1].endTime : 0;


            let endTime = starttime + duration

            newVideoList.push({
              id: newVideoList.length,
              src: videoElement.src,
              file: videoFile,
              duration: duration,
              x: 0,
              y: 0,
              startTime: starttime,
              endTime: endTime,
              trimmStart: 0.0,
              trimmEnd: 0.0,
              width: 540,
              height: 960
            });

            console.log(newVideoList)

            resolve();
          };
        });

      } catch (error) {
        console.error("Error fetching video: ", error);
      }
      
    }

    decreaseCredits()

    setVideoList(newVideoList)

    addSubtitlesToVideo(mergeWords(subtitles, 2))

    setLoadingVideoTitle(null);

    onRequestClose()
  };

  function mergeWords(arr, wordcount) {
    const result = [];
  
    for (let i = 0; i < arr.length; i += wordcount) {
      let wordsToMerge = arr.slice(i, i + wordcount);
  
      // Join words with spaces and remove commas from each word
      let merged = {
        word: wordsToMerge.map(w => w.text.replace(/,/g, '')).join(' '),
        start: wordsToMerge[0].start,
        end: wordsToMerge[wordsToMerge.length - 1].end
      };
  
      result.push(merged);
    }
  
    return result;
  }
  

  const generateSubtitles = (audioData) => {
    const form = new FormData();
    form.append("file", audioData, "voiceover.mp3"); // Assuming you want to send the audio file with a specific name

    axios.post(`${process.env.REACT_APP_BACKEND_URL}/subtitles`, form)
      .then((response) => {
        console.log(response.data)
        setSubtitles(response.data); // Assuming the subtitles are returned in a format you can directly set
        // Here you might want to integrate subtitles with your video or display them somehow
      })
      .catch((error) => {
        console.error("Error generating subtitles:", error);
      });
  };


  const generateVoice = async () => {

    axios.post(process.env.REACT_APP_BACKEND_URL + "/tts", {
      text: videoScript,
      voice: selectedVoice,
    }, {
      responseType: 'blob'
    })
      .then(async (response) => { // Mark this function as async
        await uploadSound(response.data).then(duration => {
        });


        generateSubtitles(response.data);

      })
      .catch((error) => {
        console.error("Error generating voice:", error);
      });
  };



  function addSubtitlesToVideo(data) {

    let newArr = [...textList]


    let videoElement = document.getElementById('canvasbg');




    // Get the real dimensions of the video
    let videoWidth = videoElement.clientWidth;
    let videoHeight = videoElement.clientHeight;



    let centerX = videoWidth / 2; // Center on the X-axis
    let centerY = videoHeight - videoHeight * 0.3; // Near the bottom

    const avgCharWidth = 10; // Rough estimate of character width in pixels

    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');

    // Set the font to match your subtitle rendering settings
    ctx.font = '30px KomikaTitle'; //

    for (let i = 0; i < data.length; i++) {
      let text = data[i].word;
      // Calculate approximate text width
      let metrics = ctx.measureText(text);
      let textWidth = metrics.width;

      newArr.push({
        "id": textList.length + i,
        group: 1,
        tag: "subtitle",
        "text": " " + text + " ",
        "x": centerX - textWidth / 2, // Adjust x position to center the text 
        "y": centerY,
        startTime: data[i].start * 1000,
        endTime: data[i].end * 1000,
        color: "#FFEC00",
        backgroundColor: 'rgba(0,0,0,0)',
        size: 30,
        visible: false,
        font: "KomikaTitle",
        cornerRadius: 10
      })
    }

    console.log(newArr)
    setTextList(newArr);
  }

  function uploadSound(sound) {
    return new Promise((resolve, reject) => {
      var audio = document.createElement('audio');


      if (typeof sound !== 'string') {
        // If 'sound' is not a string, assume it's a Blob or File and use createObjectURL
        audio.src = URL.createObjectURL(sound);
      } else {
        // If 'sound' is a string, handle it accordingly
        // For example, if 'sound' is expected to be a direct URL to an audio file when it's a string:
        audio.src = sound;
      }

      function setDuration() {
        let duration = audio.duration * 1000;
        let al = cloneDeep(audioList);
        let starttime = 0;
        if (audioList.length > 0) {
          starttime = audioList[audioList.length - 1].endTime;
        }

        al.push({
          id: audioList.length,
          "src": audio.src,
          file: sound,
          duration: duration,
          startTime: starttime,
          endTime: starttime + duration,
          trimmStart: 0.0,
          trimmEnd: 0.0
        });

        setAudioList(al);

        resolve(duration); // Resolve the promise with the value you want to return
      }

      audio.addEventListener("loadedmetadata", setDuration);
    });
  }




  const renderExamples = () => {
    const nichesWithTitles = [
      {
        niche: "Futuristic Cityscapes",
        titles: [
          "Skyline of 2050: Flying Cars and Neon Lights",
          "Underwater City Life: Living Among Sea Creatures",
          "Mars Colonization: A Day in the Red City",
          "The Floating Islands of the Future",
          "Virtual Reality Cities: Where Pixels Meet Concrete",
          "2050: The Year of Solar Skyscrapers",
          "Robotic Metropolis: The AI Overlords",
          "Anti-Gravity Buildings in Action",
          "The Great Green City: Eco-Friendly and Sustainable",
          "Night in Cyber Tokyo: Neon and Noise",
          "Artificial Intelligence Run New York",
          "Bioluminescent Streets in Bio-Engineered Cities",
          "The Diamond Dome Cities of Jupiter",
          "Smart Cities: The Internet of Everything",
          "The Arctic Cities: Life on Ice",
          "Rebirth of Detroit: From Motor City to Tech Hub",
          "Silicon Valley: From Orchards to Servers",
          "Cloud Cities: Living Above the Sky",
          "Lunar Settlements: Prospecting the Moon",
          "Space Elevator Cities: Gateways to the Stars",
          "Time-Travel Towns: Visiting History",
          "Post-Apocalyptic Paris: Reimagined",
          "The Subterranean Cities of 2100",
          "Desert Cities: Oasis of the Future",
          "Tropical Paradise Cities: Beyond the Beach",
          "Wind-Powered Wonders of the Western Shore",
          "The Great Walls of Future Beijing",
          "New Age Atlantis: Myth Meets Technology",
          "Quantum Cities: At the Edge of Physics",
          "Living Bridges: The Moving Metropolises"
        ]
      },
      {
        niche: "Virtual Vacation Tours",
        titles: [
          "A Day at the Virtual Bahamas: Sun, Sea, and Sand",
          "Explore the Ruins of Machu Picchu from Your Living Room",
          "The Northern Lights: A Spectacular Virtual Show",
          "Walk the Streets of Paris: A Guided VR Tour",
          "Dive with Sharks: An Underwater VR Experience",
          "Safari in the Serengeti: Close Encounters with Wildlife",
          "Tour the Vineyards of Tuscany Without Leaving Home",
          "A Virtual Hike Through Yellowstone National Park",
          "Discover Ancient Egypt: Pyramids and Pharaohs",
          "The Wonders of the Great Wall of China: A VR Journey",
          "Exploring the Depths of the Great Barrier Reef",
          "Visit Venice: Gondola Rides Through Historic Canals",
          "A Day in Tokyo: Sushi, Sumo, and Sakura",
          "New York City at Christmas: Lights, Camera, Action!",
          "The Hidden Gems of Bali: Beaches and Temples",
          "St. Petersburg's White Nights: Art and Elegance",
          "Carnival in Rio: Colors, Costumes, and Samba",
          "Journey Through the Amazon Rainforest",
          "The Festivals of India: Colors and Celebrations",
          "Exploring the Artistic Alleys of Barcelona",
          "Island Hopping in Greece: Mykonos to Santorini",
          "The Castles of Scotland: A Historical Tour",
          "The Vineyards of Napa Valley: Wine Tasting Tour",
          "A Virtual Pilgrimage to Mecca: Faith and Devotion",
          "Tour the Louvre Museum: Masterpieces Unveiled",
          "Hiking the Inca Trail to Machu Picchu",
          "The Cherry Blossoms of Kyoto: A Seasonal Spectacle",
          "Surfing in Hawaii: Catching Virtual Waves",
          "The Oktoberfest Experience: Beer and Bavaria",
          "Exploring the Underground Caves of Vietnam"
        ]
      },
      {
        niche: "Alternate History Scenarios",
        titles: [
          "What If the Library of Alexandria Had Never Burned?",
          "If the Dinosaurs Hadn't Gone Extinct",
          "The World If Rome Never Fell",
          "If the Industrial Revolution Happened in China",
          "What If the American Revolution Failed?",
          "If the Internet Was Invented in the 1800s",
          "The World If Napoleon Had Conquered Russia",
          "If Electricity Had Never Been Harnessed",
          "What If the Berlin Wall Had Never Fallen?",
          "If Women Had Always Had Equal Rights",
          "The World If the Cold War Had Gone Hot",
          "What If the Titanic Had Missed the Iceberg?",
          "If the Aztec Empire Had Defeated the Spanish",
          "What If Cars Had Never Been Invented?",
          "If the Moon Landing Had Failed",
          "The World If Penicillin Was Never Discovered",
          "What If the Gold Rush Never Happened?",
          "If the Mongol Empire Had Continued to Expand",
          "What If Prohibition Had Never Been Repealed?",
          "If the World Was a Matriarchy",
          "What If the Age of Piracy Lasted Into the 1900s?",
          "If the British Empire Still Existed",
          "What If the Maya Civilization Had Flourished Until Today?",
          "If Alexander the Great Had Lived Longer",
          "The World If Jazz Never Evolved",
          "What If the French Revolution Had Been Suppressed?",
          "If the Beatles Had Never Broken Up",
          "What If Television Was Never Invented?",
          "If Coffee Had Never Been Discovered",
          "The World If Nuclear Power Had Been Abandoned After WWII"
        ]
      },
      {
        niche: "Extreme Weather Events",
        titles: [
          "Inside a Category 5 Hurricane: Virtual Experience",
          "Surviving the World's Largest Tornado",
          "The Day the Sky Turned Green: Severe Weather Phenomena",
          "Mega Tsunami: The Impact on Modern Cities",
          "Super Typhoon: A 360 Virtual Tour",
          "Exploring the Eye of a Storm",
          "The Fury of a Thousand Lightning Strikes",
          "Blizzard of the Century: A Frosty Tale",
          "The Great Floods and How Cities Survive",
          "Surviving a Sandstorm in the Sahara",
          "Wildfires: Flames That Reshape Forests",
          "Drought: The Silent Killer",
          "Ice Storms: The Frozen Aftermath",
          "Cyclones vs. Hurricanes: What's the Difference?",
          "The Heatwave That Melted a City",
          "The Monster Hailstorm: When Ice Attacks",
          "Volcanic Winters: The Cold and Dark",
          "The Thunderstorm That Powered a City",
          "When Rain Turns to Acid: Realities of Acid Rain",
          "Avalanches: The Snowy Surge",
          "Surviving Nature's Fury: Prep and Recovery",
          "Earthquakes: Shaking the Modern World",
          "The Tornado That Redefined Wind Power",
          "Hurricane Havoc on Coastal Cities",
          "The Blizzard That Stopped Time",
          "Record-Breaking Rain: Floods and Flows",
          "Chasing Storms: Inside the Twister",
          "The Lightning Storm of the Century",
          "Surviving Extreme Heat in Death Valley",
          "When Nature Roars: The Power of Thunderstorms"
        ]
      },
      {
        niche: "Mysteries of the Universe",
        titles: [
          "Exploring Black Holes: The Universe's Mystery",
          "The Secrets of Dark Matter and Dark Energy",
          "Time Travel Theories: Science Fact or Fiction?",
          "Alien Civilizations: Are We Alone?",
          "The Big Bang: The Explosion That Started Everything",
          "Multiverse Theory: Infinite Realities",
          "The Life and Death of Stars",
          "Quantum Realms: The Weird World of the Very Small",
          "The Search for Exoplanets: Earth's Cousins",
          "Gravitational Waves: Listening to the Cosmos",
          "The Possibility of Parallel Universes",
          "The Mystery of Neutron Stars",
          "Cosmic Strings: The Universe's Hidden Structure",
          "Pulsars: The Lighthouses of the Galaxy",
          "The Enigma of the Speed of Light",
          "The Expansion of the Universe: Faster Than Light?",
          "Time Dilation: The Effect of Relativity",
          "Wormholes: Gateways Across the Universe",
          "The Unseen: What Lies Beyond Visible Light",
          "Cosmic Microwave Background: Echoes of the Big Bang",
          "The Role of Black Holes in Galaxy Formation",
          "The Oddity of Quantum Entanglement",
          "The Fate of the Universe: Big Crunch, Rip, or Freeze?",
          "Mars: Our Future Second Home?",
          "The Aurora: Nature's Light Show",
          "The Zodiac Light: Sunlight Scattering in Space",
          "Dark Skies: Preserving Our View of the Cosmos",
          "The Goldilocks Zone: Where Life Can Exist",
          "Comets: The Nomads of the Solar System",
          "The Kuiper Belt: Beyond Neptune"
        ]
      },
      {
        niche: "Cybersecurity Threats",
        titles: [
          "The Most Daring Cyber Heists of the 21st Century",
          "AI in Cybersecurity: Defending the Digital Frontier",
          "The Evolution of Malware: From Viruses to Advanced Ransomware",
          "The Dangers of Phishing: How to Recognize Scams",
          "The Impact of Cyber Warfare on National Security",
          "Personal Data Protection: Tips and Tricks",
          "The Future of Biometric Security",
          "The Dark Web: What Lies Beneath the Internet",
          "Social Engineering: The Art of Human Hacking",
          "Quantum Computing: The Future Threat to Encryption",
          "Cryptocurrency and Blockchain: New Avenues for Hackers",
          "IoT Security: Protecting Your Smart Devices",
          "The Role of Artificial Intelligence in Enhancing Security",
          "Mobile Security: Safeguarding Your Digital Life on the Go",
          "Zero-Day Exploits: The Invisible Threats",
          "Cyber Espionage: Spying in the Digital Age",
          "The Rise of DDoS Attacks: Crashing Websites and Services",
          "Cloud Security: Protecting Data in the Cloud",
          "Cybersecurity Laws and Regulations: What You Need to Know",
          "Hacking the Hackers: The Ethics of Retaliatory Cyberattacks",
          "Insider Threats: When Employees Become Risks",
          "Supply Chain Attacks: The Weakest Link",
          "Data Breaches: How They Happen and How to Prevent Them",
          "Email Security: Guarding Against Unauthorized Access",
          "Password Management: Best Practices for Security",
          "Secure Coding Practices: Building Safer Software",
          "The Anatomy of a Cyber Attack",
          "Protecting Against ATM and Card Skimming",
          "The Psychology of Cybersecurity: Understanding Hacker Motives",
          "Future Trends in Cyber Threats and Security"
        ]
      },
      {
        niche: "Bizarre Medical Cases",
        titles: [
          "The Man Who Could Not Forget: A Memory Phenomenon",
          "The Mystery of Spontaneous Human Combustion",
          "Allergic to Water: The Strange Case of Aquagenic Urticaria",
          "The Woman Who Cries Blood: Haemolacria",
          "Surviving Without Sleep: The Fatal Familial Insomnia Story",
          "The Boy with the Magnetic Body",
          "Walking Corpse Syndrome: The People Who Think They're Dead",
          "The Real Benjamin Button: Progeria Cases",
          "Stone Man Syndrome: When Body Tissue Turns to Bone",
          "The Girl Who Never Ages: A Case of Syndrome X",
          "Alien Hand Syndrome: The Hand with a Mind of Its Own",
          "The Woman Who Feels No Pain",
          "The Man with Two Hearts: A Medical Miracle",
          "Blue Skin Disorder: The Blue People of Kentucky",
          "Tree Man Syndrome: The Human Bark",
          "Eating Nails and Glass: The Dangers of Pica Disorder",
          "The Man Who Hears Music in His Head",
          "Living with Cotard's Delusion: The Walking Dead Among Us",
          "The Incredible Expanding Skull",
          "Mirror Touch Synesthesia: Feeling What Others Feel",
          "The Boy Who Never Gets Cold",
          "The Girl with X-Ray Vision",
          "The Human Computer: Extreme Calculation Abilities",
          "Surviving a Spear Through the Brain",
          "The Man Who Could Not Stop Eating",
          "The Woman with a Second Voice",
          "The Man with a Hole in His Stomach",
          "The Never-Healing Wound: A Medical Mystery",
          "The Baby with the Mermaid Syndrome",
          "The Woman Who Smells Colors: A Case of Synesthesia"
        ]
      },
      {
        niche: "Time-Travel Adventures",
        titles: [
          "A Day with the Dinosaurs: Jurassic Journey",
          "The Lost Library of Alexandria: A Time-Travel Expedition",
          "Preventing the Fall of Rome: A Time Traveler's Quest",
          "The Signing of the Declaration: Witnessing History",
          "Titanic: Saving the Unsinkable Ship",
          "The Wild West: Gunslingers and Gold Rush",
          "The Battle of Waterloo: Rewriting History",
          "The Invention of the Internet: Accelerating Progress",
          "Time Travel to Ancient Egypt: Building the Pyramids",
          "The Renaissance: Meeting the Masters",
          "The Future: A Glimpse into 3000",
          "Surviving the Black Plague: Lessons from the Past",
          "Witnessing the Big Bang: The Ultimate Beginning",
          "The French Revolution: Liberty, Equality, Fraternity",
          "Meeting Shakespeare: A Playwright's Tale",
          "The Roaring Twenties: Jazz, Flappers, and Prohibition",
          "The Discovery of America: Columbus's Voyage",
          "The Industrial Revolution: Steam, Steel, and Innovation",
          "The Civil Rights Movement: Marching Alongside Giants",
          "The Moon Landing: One Small Step for Man",
          "Ancient Greece: Philosophy and Democracy",
          "The Viking Age: Exploring with Norsemen",
          "The Salem Witch Trials: Unraveling the Hysteria",
          "The Age of Exploration: Sailing Unknown Seas",
          "The Fall of the Berlin Wall: Tears and Cheers",
          "The Golden Age of Piracy: Life on the High Seas",
          "The Ming Dynasty: China's Great Empire",
          "The Romanovs: The Last Days of the Tsars",
          "Medieval Japan: Samurai and Shoguns",
          "The Spanish Inquisition: Trials and Tribulations"
        ]
      },
      {
        niche: "Urban Legends Reimagined",
        titles: [
          "The Haunted Mirror: Reflections of the Past",
          "Beneath the City: The Tunnels That Time Forgot",
          "The Cursed Amulet: Mystery of the Pharaohs",
          "The Ghost Ship: Lost and Found at Sea",
          "The Watcher in the Woods: Eyes in the Dark",
          "The Vanishing Hitchhiker: Roads to Nowhere",
          "The Eternal Wanderer: The Legend of the Lost Traveler",
          "The Bell Witch: The Haunting of Tennessee",
          "The Phantom of the Opera House: Behind the Curtains",
          "The Lady in White: The Bridge to the Other Side",
          "The Beast of the Lake: What Lurks Beneath",
          "The Crying Statue: Tears of Stone",
          "The Lost Town: Swallowed by Fog",
          "The Enchanted Doll: Possessed by Spirits",
          "The Forbidden Forest: Trees That Whisper",
          "The Witch’s Window: A Portal to the Paranormal",
          "The Secret of the Seven Gates: Beyond the Threshold",
          "The Shapeshifter: Faces of the Night",
          "The Shadow People: Watchers in the Dark",
          "The Haunted Lighthouse: Beacon of the Damned",
          "The Mothman: Wings of Terror",
          "The Haunting Melody: Notes From the Unknown",
          "The Mystery of the Abandoned House: Doors That Shouldn’t Open",
          "The Legend of the Sleeping Stones: Awakening Ancient Powers",
          "The Curse of the Pirate’s Treasure: X Marks the Spot",
          "The Whispering Walls: Voices Without Faces",
          "The Serpent in the Sewers: Beneath the City’s Heart",
          "The Frozen Ghost: Chills in the Night",
          "The Black Dog: Omen of Doom",
          "The Tower of Silence: Screams in the Wind"
        ]
      },
      {
        niche: "Famous Unsolved Mysteries",
        titles: [
          "The Lost Colony of Roanoke: Vanished Without a Trace",
          "The Identity of Jack the Ripper: London’s Nightmare",
          "The Mystery of Amelia Earhart: Lost in Flight",
          "The Disappearance of the Mary Celeste Crew: Ghost Ship",
          "The Zodiac Killer: Decoding the Letters",
          "The Riddle of the Sphinx: Unveiling Ancient Secrets",
          "The Secret of Oak Island: Treasure or Trick?",
          "The Voynich Manuscript: A Book No One Can Read",
          "The Bermuda Triangle: Vanishing Into Thin Air",
          "The Loch Ness Monster: Myth or Monster?",
          "The Disappearance of Jimmy Hoffa: The Endless Search",
          "The Dyatlov Pass Incident: A Chilling Mystery",
          "The Black Dahlia Murder: Hollywood’s Haunt",
          "The Mystery of the Crystal Skulls: Legend or Science?",
          "The Shroud of Turin: Relic or Replica?",
          "The Atlantis Enigma: Lost City or Legend?",
          "The Nazca Lines: Messages to the Gods",
          "The Yeti: Footprints in the Snow",
          "The Mystery of Stonehenge: Stones of the Ancients",
          "The Curse of Tutankhamun: The Pharaoh’s Revenge",
          "The Assassination of JFK: The Conspiracy Theories",
          "The Mystery of D.B. Cooper: The Skyjacker Who Vanished",
          "The Ghost Ship of the Arctic: The Franklin Expedition",
          "The Mona Lisa Smile: Secrets of a Masterpiece",
          "The Lost Treasure of the Templars: Hidden Wealth",
          "The Roswell Incident: UFO or Weather Balloon?",
          "The Beast of Gévaudan: The Wolf That Terrorized France",
          "The Alcatraz Escape: Did They Survive?",
          "The Mystery of the Princes in the Tower: Royal Secrets",
          "The Disappearance of Flight MH370: Above the Depths"
        ]
      },
      {
        niche: "Quantum Physics Explained",
        titles: [
          "Understanding the Uncertainty Principle: Heisenberg’s Mystery",
          "The Double Slit Experiment: Particle or Wave?",
          "Quantum Entanglement: Spooky Action at a Distance",
          "Schrodinger's Cat: Alive and Dead?",
          "Quantum Teleportation: Science Fiction or Reality?",
          "The Quantum Computer: A New Era of Computing",
          "Quantum Tunneling: Passing Through Walls",
          "The Role of Observers in Quantum Mechanics",
          "Quantum Superposition: Being in Two Places at Once",
          "The Quantum Zeno Effect: Watching a Pot Never Boils",
          "Quantum Cryptography: Unbreakable Codes",
          "The Many-Worlds Interpretation: Infinite Possibilities",
          "Quantum Field Theory: The Fabric of the Universe",
          "The Pilot-Wave Theory: Guiding the Quantum World",
          "Quantum Gravity: The Search for a Unified Theory",
          "The Collapse of the Wave Function: When Quantum Meets Classical",
          "Quantum Biology: Life on the Edge",
          "The Quantum Hall Effect: A New State of Matter",
          "Quantum Foam: The Structure of Space-Time",
          "The Quantum Measurement Problem: To See or Not to See",
          "The EPR Paradox: Einstein’s Dilemma with Quantum Theory",
          "Quantum Chromodynamics: The Force Within Protons and Neutrons",
          "The Race for Quantum Supremacy: Countries and Companies",
          "Quantum Healing: The Mind-Body Quantum Connection",
          "Quantum Consciousness: Are Our Brains Quantum Computers?",
          "Quantum Immortality: Living in a Parallel Universe?",
          "The Casimir Effect: Vacuum Forces at Play",
          "Quantum Fluctuations: The Jitters of Nothingness",
          "Entanglement Swapping: Connecting Distant Particles",
          "The Quantum Internet: Networking on a Quantum Level"
        ]
      },
      {
        niche: "Artificial Intelligence Milestones",
        titles: [
          "Deep Blue vs. Kasparov: The Match That Changed Chess",
          "AlphaGo’s Victory: A New Chapter in AI",
          "GPT-3 and the Future of Conversational AI",
          "The Turing Test: Can Machines Think?",
          "Autonomous Vehicles: The Road to Self-Driving Cars",
          "AI in Medicine: Predicting Diseases Before They Happen",
          "Robotics in Surgery: Precision Beyond Human Limits",
          "AI in Space Exploration: The Next Frontier",
          "Natural Language Processing: Understanding Human Speech",
          "AI in Art: Can Machines Be Creative?",
          "The Birth of Artificial Neural Networks",
          "The Impact of AI on Jobs and the Economy",
          "Ethical AI: Balancing Innovation with Responsibility",
          "Machine Learning Algorithms That Changed Industries",
          "AI in Agriculture: Farming with Precision",
          "Facial Recognition Technology: Security vs. Privacy",
          "AI in Finance: Trading and Risk Management",
          "AI in Sports: Enhancing Performance and Strategy",
          "AI-Driven Climate Models: Predicting the Future",
          "AI in Entertainment: Personalized Content Creation",
          "Quantum Computing and AI: A Powerful Combination",
          "AI in Cybersecurity: Guarding the Digital World",
          "The Global AI Race: Who’s Leading the Way?",
          "AI in Education: Personalized Learning Experiences",
          "AI in Retail: Transforming Shopping Experiences",
          "AI and the Future of Social Networks",
          "AI in Music: Composing the Hits of Tomorrow",
          "AI in Real Estate: Predicting Market Trends",
          "AI for Public Safety: Predicting and Preventing Crimes",
          "AI and Ethics: The Importance of Transparency"
        ]
      },
      {
        niche: "Revolutionary Inventions That Changed the World",
        titles: [
          "The Printing Press: Spreading Knowledge Worldwide",
          "The Steam Engine: Powering the Industrial Revolution",
          "The Light Bulb: Illuminating the Dark",
          "The Internet: Connecting the Globe",
          "The Automobile: Mobilizing the Masses",
          "The Telephone: Shrinking Distances",
          "The Airplane: The Sky is No Longer the Limit",
          "Vaccination: The Fight Against Deadly Diseases",
          "The Camera: Capturing Moments in Time",
          "The Computer: From Room-Sized Machines to Pocket Devices",
          "Penicillin: The Miracle Drug",
          "The Transistor: The Building Block of Modern Electronics",
          "The Refrigerator: Preserving Food, Preserving Health",
          "The Television: The Revolution of Visual Media",
          "The Microwave Oven: Revolutionizing Cooking",
          "The GPS: Navigating the World",
          "The Sewing Machine: The Fabric of Modern Fashion",
          "The Atomic Bomb: The Power That Changed Warfare",
          "The Birth Control Pill: Empowering Choices",
          "The Personal Computer: Empowering Individuals",
          "The World Wide Web: Information at Your Fingertips",
          "Solar Panels: Harnessing the Sun’s Energy",
          "The Barcode: Streamlining Commerce",
          "The Credit Card: Changing Financial Transactions",
          "The Digital Camera: A New Era of Photography",
          "The Smartwatch: Technology on Your Wrist",
          "3D Printing: From Concept to Creation",
          "The Drone: Eyes in the Sky",
          "The Electric Car: Driving Towards a Greener Future",
          "The Blockchain: A New Kind of Trust"
        ]
      },
      {
        niche: "Hooking Horror Stories",
        titles: [
          "The Midnight Caller: Terror on the Line",
          "The Campsite Stalker: Shadows in the Forest",
          "The Haunted Doll: Whispers in the Night",
          "The Old Mansion on the Hill: Screams in the Silence",
          "The Fog that Knows Your Name: Lost in the Mist",
          "Basement Whispers: The Sounds of the Unknown",
          "The Figure at the Window: Watched in the Dark",
          "The Abandoned Hospital: Echoes of the Past",
          "The Ghostly Visitor: Knocking at Midnight",
          "The Curse of the Old Book: Words That Shouldn't Be Read",
          "The Secret Beneath the Lake: Submerged Horrors",
          "The Attic Light: Flickering Fears",
          "The Forgotten Cemetery: Restless Spirits",
          "The Mirror in the Attic: Reflections of Terror",
          "The Phantom Train: The Last Journey",
          "The Cry in the Night: A Child's Horror",
          "The Locked Room: Secrets Inside",
          "The Creature in the Woods: Eyes Watching",
          "The Lost Videotape: Scenes of the Surreal",
          "The Painting That Watches: Eyes That Follow",
          "The Haunting Melody: Music from Nowhere",
          "The Disappearing Path: Trails to Nowhere",
          "The Séance: Voices from Beyond",
          "The Old Clown Statue: Smiles That Kill",
          "The Phone That Shouldn’t Ring: Calls from the Dead",
          "The Horror in the Snow: Footprints to Nowhere",
          "The Warning on the Radio: Messages in Static",
          "The Night the Stars Went Out: Darkness Descends",
          "The Bridge Over the Ravine: Crossing into Terror",
          "The Message Written in Blood: Walls That Speak"
        ]
      }
      ,
      {
        "niche": "Greek Mythology Creatures and Stories",
        "titles": [
          "The Wrath of Zeus: Thunderbolts of Fury",
          "The Labyrinth of Minos: Secrets of the Maze",
          "The Gorgon's Stare: Medusa's Curse",
          "The Golden Fleece: Quest of the Argonauts",
          "The Fall of Icarus: Wings of Hubris",
          "The Odyssey of Odysseus: Voyage of the Cursed",
          "The Titan's Revolt: Clash of the Immortals",
          "The Oracle of Delphi: Prophecies of Fate",
          "The Trials of Hercules: The Twelve Labors",
          "The Curse of Narcissus: Reflections of Doom",
          "The Underworld of Hades: Gates of the Dead",
          "The Sirens' Call: Songs of the Doomed",
          "The Fury of Ares: Battlefields of Wrath",
          "The Blessings of Athena: Wisdom and Warfare",
          "The Seduction of Persephone: Seasons of Abduction",
          "The Rage of Hera: Queen's Jealousy",
          "The Chariot of Helios: Ride Across the Sky",
          "The Birth of Aphrodite: Beauty from the Sea",
          "The Transformation of Arachne: Weaving Tragedy",
          "The Fires of Hephaestus: Forge of the Gods",
          "The Hunt of Artemis: Moonlit Shadows",
          "The Trickery of Hermes: The Divine Messenger's Games",
          "The Poison of Hydras: Lerna's Deadly Challenge",
          "The Wings of Pegasus: Flight to the Heavens",
          "The Haunting of the Furies: Eternal Vengeance",
          "The Feast of Dionysus: Wine and Revelry",
          "The Riddles of the Sphinx: Enigmas of Death",
          "The Judgment of Paris: Apple of Discord",
          "The Abduction of Ganymede: The Eagle's Clutch",
          "The Wrath of the Harpies: Storm of Retribution"
        ]
      }


    ];



    const handleSelect = (eventKey) => {
      const nicheData = nichesWithTitles.find(niche => niche.niche === eventKey);
      setSelectedTitles(nicheData ? nicheData.titles : []);
      setSelectedNiche(eventKey);
    };


 


    return (
      <div>
        <Dropdown onSelect={handleSelect}>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {selectedNiche ? selectedNiche:"Select a niche and topic"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {nichesWithTitles.map((item, index) => (
              <Dropdown.Item key={index} eventKey={item.niche}>{item.niche}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Row style={{ overflowY: 'auto', maxHeight:"50vh"}}>
          {selectedTitles.map((title, index) => (
            <Col key={index} sm={6} className="mb-3">
              <div style={{
                cursor: 'pointer',
                border: "solid #181818 2px", borderRadius: "10px", backgroundColor: "#2b2b2b", padding: "10px"
              }} onClick={() => setDescription(title)}>
                <p><strong>{title}</strong></p>
              </div>
            </Col>
          ))}
        </Row>

      </div>
    );
  };

  const voices = [
    { name: "Alloy", id: "alloy" },
    { name: "Echo", id: "echo" },
    { name: "Fable", id: "fable" },
    { name: "Onyx", id: "onyx" },
    { name: "Nova", id: "nova" },
    { name: "Shimmer", id: "shimmer" },
   
  ];
  

  const handleVoiceChange = (event) => {
    const newSelectedVoice = event.target.value;
    setSelectedVoice(newSelectedVoice);
  };


  const renderVoiceSelectionDropdown = () => {
    return (
      <Form.Group controlId="voiceSelection">
        <Form.Label>Select a Voice</Form.Label>
        <Form.Select aria-label="Voice selection" value={selectedVoice} onChange={handleVoiceChange}>
          {voices.map(voice => (
            <option key={voice.id} value={voice.id}>
              {voice.name}
            </option>
          ))}
        </Form.Select>
    
      </Form.Group>
    );
  };

  const loadVideoIntoEditor = async (video) => {

    setLoadingVideoTitle(video.title); // Set loading state before the asynchronous operation

    console.log(video)
    let audioURL = `${process.env.REACT_APP_BACKEND_URL}/static/` + video['audioPath']
    uploadSound(audioURL)
    setSubtitles(video['subtitles'])
    console.log(video['subtitles'])
    setVideos(video['videoPaths'])
    

  };


  useEffect(() => {
    console.log("load start")
    addSelectedVideosToEditor()
    console.log("load end")
  }, [videos])


  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
            {oldVideos &&
              oldVideos.slice().reverse().map((video, index) => (
                <div key={index} style={{
                  border: '3px solid darkgray',
                  borderRadius: '10px',
                  padding: '10px',
                  margin: '10px',
                  maxWidth: '25vh', // Adjusted to fit 6 videos per row
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                  <p style={{ margin: '5px 0', fontSize: 'calc(8px + 1vmin)', textAlign: 'center', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {video.title}
                  </p>
                  <video
                  autoPlay
                  muted
                    style={{ width: '100%', height: 'auto', display: 'block' }}
                    src={`${process.env.REACT_APP_BACKEND_URL}/static/${video.thumbnail}`}
                    onClick={() => loadVideoIntoEditor(video)}
                    alt={video.title}
                    onError={(e) => { e.target.parentNode.style.display = 'none'; }}
                  />
                  {loadingVideoTitle === video.title ? (
                    <Spinner style={{ display: 'block', margin: '10px auto' }} /> // Show spinner when loading
                  ) : (
                    <Button style={{ display: 'block', margin: '10px auto' }} onClick={() => loadVideoIntoEditor(video)}>Add to Editor</Button>
                  )}
                </div>
              ))}
          </div>
        );
        
        
        

      case 1:
        return (
          <div>

            <Row>
              {renderExamples()} {/* Render the 2x2 grid of examples */}
            </Row>
            <Row>
              <p></p>
            </Row>

            
            {selectedNiche ? null:
            
            <div><p>or</p>

            <h4>Provide a topic directly</h4>
            </div>
              
            }

          
            <Form.Group className="mb-3">

              <Form.Control as="textarea" rows={3} value={description} onChange={(e) => setDescription(e.target.value)} />
            </Form.Group>


          </div>
        );
      case 2:
        return (
          <div>{videoScriptLoading ?
            <div>
              <h3>Generating video script</h3>
              <FakeProgress duration={10} />
            </div>
            : <div><Form.Group className="mb-3">
              <Form.Label>Step 2 of 4: Review and Edit Your Video Script</Form.Label>
              <Form.Control as="textarea" rows={9} value={videoScript} onChange={(e) => setVideoScript(e.target.value)} />
            </Form.Group>

              {renderVoiceSelectionDropdown()}


            </div>
          }

          </div>
        );
      case 3:
        return (
          <div>
            {videoGenerationLoading ? <div>
              <h3>Generating Video</h3>
              <h4>Do not close or switch tabs in this process</h4>
              <FakeProgress duration={60} /></div> : null}

          </div>
        );
    }
  };

  const closePaymentModel = () => {
    setPaymentModel(false)
    getCredits()
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          borderRadius: '15px',
          border: '1px solid #ffffff',
          boxShadow: '0px 0px 10px 1px #0a8dff',
          backgroundColor: '#181818',
          color: '#ffffff',
          padding: '20px', // Ensure there's padding for aesthetics
          display: 'flex',
          flexDirection: 'column', // This makes the content grow vertically
          height: '90vh', // Adjust based on your modal size preferences
        },
      }}
    >
      <Container style={{ flex: 1, overflowY: 'auto' }}> {/* Content container with scrolling */}
        <h1>AI Video Generator</h1>
        {renderStepContent()}
      </Container>
      <div style={{
        marginTop: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'space-between', // Added to space out the children
      }}> {/* Fixed to bottom */}

        {isAuthenticated ?
          <div style={{ display: "flex", alignItems: "center" }}>
            <UserIcon />
          </div>
          : " "
        }



        <PaymentModal isOpen={paymentModel} onRequestClose={closePaymentModel}></PaymentModal>

        {/* Conditionally render the Next/Finish button to change on the last step */}
        {currentStep < 4 ? (
          isAuthenticated ?
            <div>


              {(currentStep === 0) ? <Button onClick={handleNext} style={{ marginLeft: 'auto' }}>
                Create New
              </Button> : null}

              {(currentStep === 1) ? <Button disabled={!description} onClick={handleNext} style={{ marginLeft: 'auto' }}>
                Next
              </Button> : null}



              {(currentStep === 2 && videoScript) ? <Button onClick={handleNext} style={{ marginLeft: 'auto' }}>
                Create AI Video
              </Button> : null}

            </div>





            : (
              <Button onClick={() => loginWithPopup()} style={{ marginLeft: 'auto' }}>
                Next
              </Button>
            )
        ) : null}
      </div>
    </Modal>
  );

}
export default AiVideoModal;

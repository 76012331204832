import { HexColorPicker } from "react-colorful";
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { elementState, textListState } from '../atoms';
import Form from 'react-bootstrap/Form';
import cloneDeep from 'lodash/cloneDeep';
import Button from 'react-bootstrap/Button';
import { SketchPicker } from 'react-color';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';



const TextEditor = () => {

  const [textList, setTextList] = useRecoilState(textListState);
  const [element, setElement] = useRecoilState(elementState);
  const [backgroundColor, setBackgroundColor] = useState({
    r: '0',
    g: '0',
    b: '0',
    a: '0.5',
  });

  function changetext(text) {
    let newList = [...textList].map((item) => {
      let newData = { ...item }
      if (item.id === element) {
        newData.text = text;
        return newData;
      }
      else return item;
    });
    setTextList(newList);
  }

  function changeCornerRadius(cornerRadius){
    let newArr = cloneDeep(textList)
    newArr[element].cornerRadius = cornerRadius
    setTextList(newArr);
  }

  function changetextsize(size) {
    if (size > 100) {
      size = 100
    }
    let newList = [...textList].map((item) => {
      let newData = { ...item }
      if (item.id === element) {
        newData.size = size
        return newData;
      }
      else return item;
    });
    setTextList(newList);
  }


  function changecolor(color) {
    let newArr = cloneDeep(textList)
    newArr[element].color = color.hex
    setTextList(newArr)
  }

  function changebackgroundcolor(color) {
    const newBackgroundColor = { r: color.rgb.r, g: color.rgb.g, b: color.rgb.b, a: color.rgb.a };
    setBackgroundColor(newBackgroundColor)
    const rgbaColor = `rgba(${newBackgroundColor.r},${newBackgroundColor.g},${newBackgroundColor.b},${newBackgroundColor.a})`
    let newArr = cloneDeep(textList)
    newArr[element].backgroundColor = rgbaColor
    setTextList(newArr);
  }

  
  

  function changeFont(font) {
    let tL = cloneDeep(textList)
    tL[element].font = font
    setTextList(tL)
  }

  function getRedFromHex(hex) {
    // Remove '#' from start
    if (hex.charAt(0) === '#') {
      hex = hex.substr(1);
    }

    // Convert the two red values into decimal
    let redHex = hex.substring(0, 2);
    let redDecimal = parseInt(redHex, 16);

    return redDecimal;
  }


  return (<div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>

    {/*<Dropdown>
      <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
        {textList[element] != null ?
          textList[element].font
          : null
        }
      </Dropdown.Toggle>
      <br/>
      <br/>



      

      <Dropdown.Menu variant="dark">
        <Dropdown.Item style={{ fontFamily: "Times New Roman" }} onClick={(e) => { changeFont(e.target.innerText) }}>
          Times New Roman
        </Dropdown.Item>

        <Dropdown.Item style={{ fontFamily: "Georgia" }} onClick={(e) => { changeFont(e.target.innerText) }}>
          Georgia
        </Dropdown.Item>

        <Dropdown.Item style={{ fontFamily: "Garamond" }} onClick={(e) => { changeFont(e.target.innerText) }}>
          Garamond
        </Dropdown.Item>

        <Dropdown.Item style={{ fontFamily: "Arial" }} onClick={(e) => { changeFont(e.target.innerText) }}>
          Arial
        </Dropdown.Item>

        <Dropdown.Item style={{ fontFamily: "Verdana" }} onClick={(e) => { changeFont(e.target.innerText) }}>
          Verdana
        </Dropdown.Item>

        <Dropdown.Item style={{ fontFamily: "Helvetica" }} onClick={(e) => { changeFont(e.target.innerText) }}>
          Helvetica
        </Dropdown.Item>

        <Dropdown.Item style={{ fontFamily: "Courier New" }} onClick={(e) => { changeFont(e.target.innerText) }}>
          Courier New
        </Dropdown.Item>

        <Dropdown.Item style={{ fontFamily: "Lucida Console" }} onClick={(e) => { changeFont(e.target.innerText) }}>
          Lucida Console
        </Dropdown.Item>

        <Dropdown.Item style={{ fontFamily: "Monaco" }} onClick={(e) => { changeFont(e.target.innerText) }}>
          Monaco
        </Dropdown.Item>

        <Dropdown.Item style={{ fontFamily: "Brush Script MT" }} onClick={(e) => { changeFont(e.target.innerText) }}>
          Brush Script MT
        </Dropdown.Item>

        <Dropdown.Item style={{ fontFamily: "Lucida Handwriting" }} onClick={(e) => { changeFont(e.target.innerText) }}>
          Lucida Handwriting
        </Dropdown.Item>

        <Dropdown.Item style={{ fontFamily: "Copperplate" }} onClick={(e) => { changeFont(e.target.innerText) }}>
          Copperplate
        </Dropdown.Item>

        <Dropdown.Item style={{ fontFamily: "Papyrus" }} onClick={(e) => { changeFont(e.target.innerText) }}>
          Papyrus
        </Dropdown.Item>

      </Dropdown.Menu>
      </Dropdown>*/}


    {textList[element] != null ? <div style={{ padding: "1vh" }}>

    <Form>
      <Form.Group >
      <Form.Label style={{color:"white"}}>Corner Radius</Form.Label>
        <Form.Control style={{ width: '150px' }}  min="0"  size="sm" value={textList[element].cornerRadius}  type="number" onChange={(e) => changeCornerRadius(e.target.valueAsNumber)}/>
      </Form.Group>
    </Form>

      <SketchPicker

        color={textList[element].color}
        onChange={color => changecolor(color)}
      />

      <SketchPicker
        color={ backgroundColor } onChange={ color=> changebackgroundcolor(color)}/>
    </div> : null}





  </div>);
}

export default TextEditor;
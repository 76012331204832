import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { useRecoilState } from 'recoil';
import { videoListState, playState, scaleState } from '../atoms';
import Draggable from 'react-draggable'; // The default
import 'react-resizable/css/styles.css';
import { Rnd } from 'react-rnd';
import cloneDeep from 'lodash/cloneDeep';
import { Resizable } from 're-resizable';

import 'react-resizable/css/styles.css';


function Video(props) {
  const [videoList, setVideoList] = useRecoilState(videoListState);
  const [play, setPlay] = useRecoilState(playState);
  const [time, setTime] = useState(0);
  const [scale, setScale] = useState(scaleState);

  const videoRefs = useRef([]);
  const draggableRef = useRef(null);


  useEffect(() => {
    const timerElement = document.getElementById('timer');
    if (timerElement) {
      const updateTime = (event) => {
        setTime(timerElement.currentTime * 1000);
      };

      timerElement.addEventListener('timeupdate', updateTime);

      // Clean up
      return () => {
        timerElement.removeEventListener('timeupdate', updateTime);
      };
    }
  }, []);

  useEffect(() => {
    videoList.forEach((video, index) => {
      const ref = videoRefs.current[index];
      if (ref) {
        const player = videojs(ref);
        const currentVideo = videoList[index];

        // Update source if different
        if (player.currentSrc() !== currentVideo.src) {
          player.src({ src: currentVideo.src, type: 'video/mp4' });
        }

        // Check if the video should be playing
        if (time >= video.startTime && time <= video.endTime) {

          const adjustedTime = ((time - video.startTime) + (video.trimmStart || 0)) / 1000;
          const currentTimeDifference = Math.abs(player.currentTime() - adjustedTime);

          // Update the player's time only if the difference exceeds the threshold
          if (currentTimeDifference > 0.3) {
            player.currentTime(adjustedTime);
          }

          // Update the player's time only if the difference exceeds the threshold
          if (!player.paused() && !play) {
            player.pause();
          } else if (player.paused() && play) {
            player.play();
          }

        } else {
          // Pause the video if it's outside its play range
          if (!player.paused()) {
            player.pause();
          }
        }
      }
    });
  }, [time, videoList]);


  useEffect(() => {
    videoRefs.current.forEach((ref, index) => {
      if (ref && videoList[index]) {
        const player = videojs(ref);
        const currentVideo = videoList[index];

        // Update source if different
        if (player.currentSrc() !== currentVideo.src) {
          player.src({ src: currentVideo.src, type: 'video/mp4' });
        }
      }
    });
  }, [videoList]);

  /*useEffect(() => {
    if (videoRefs.current) {
      // Initialize players for each video
      videoRefs.current.forEach((ref, index) => {
        if(ref){
        if (ref.current && videoList[index]) {
          let player = videojs.getPlayer(ref.current.id);
          if (!player) {
            player = videojs(ref.current, {
              autoplay: false,
              preload: 'auto',
              sources: [{
                src: videoList[index].src,
                type: 'video/mp4'
              }]
            });
          }
        }
      }

      });
    }

  }, [videoList]);*/





  const calculateZIndex = (video) => {
    return video.stacked;
  };

  function handleDragStop(index, e, data) {
    // data contains the new position
    const { x, y } = data;

    // Update the position in your state
    let vL = cloneDeep(videoList)
    vL[index].x = x
    vL[index].y = y

    setVideoList(vL)
    console.log(vL)
  };

  function handleResizeStop(index, ref, delta) {

    // Update the size in your state
    let vL = cloneDeep(videoList);

    vL[index].width = parseInt(ref.style.width)
    vL[index].height = parseInt(ref.style.height)

    setVideoList(vL);
  };

  return (

    <div style={{}}>
      {videoList.filter(video => video.stacked == false).map((video, index) => (

        <div style={{ width: '100%', height: '100%' }}> {/* Ensure the div takes full size of Resizable */}
          <video
            key={index}
            ref={el => videoRefs.current[index] = el}
            id={`video-${index}`}
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              display: 'block',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              visibility: (time >= video.startTime && time <= video.endTime) ? 'visible' : 'hidden',

            }}
          />
        </div>
      ))}


      {videoList.filter(video => video.stacked === true).map((video, index) => (


        <Draggable
          defaultPosition={{ x: video.x, y: video.y }}
          onStop={(e, data) => handleDragStop(video.id, e, data)}
        >
          <Resizable
            lockAspectRatio={true} // Lock aspect ratio
            onResizeStop={(e, direction, ref, d) => handleResizeStop(video.id, ref, d)}

            style={{
              zIndex: '100',
              visibility: (time >= video.startTime && time <= video.endTime) ? 'visible' : 'hidden',
              position: 'absolute',
              top: 0,  // Top set to 0
              left: 0, // Left set to 0
            }}
          >
            <div style={{ width: '100%', height: '100%' }}> {/* Ensure the div takes full size of Resizable */}
              <video
                key={video.id}
                ref={el => videoRefs.current[video.id] = el}
                id={`video-${video.id}`}
                style={{
                  width: '100%', // Set video width to 100% of its parent
                  height: '100%', // Set video height to 100% of its parent
                  zIndex: '100',
                }}
              />
            </div>
          </Resizable>
        </Draggable>


      ))}
    </div>

  );
}

export default Video;
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Button } from 'react-bootstrap';
import { FacebookShareButton, RedditShareButton, TwitterShareButton, WhatsappShareButton, FacebookIcon, RedditIcon, TwitterIcon, WhatsappIcon } from 'react-share';
import userEvent from '@testing-library/user-event';
import { useAuth0 } from '@auth0/auth0-react';
import { payedState } from '../atoms'
import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
} from 'recoil';

import Spinner from 'react-bootstrap/Spinner';




const CustomModal = ({ isOpen, onRequestClose, url, title, message, estimatedTime, downloadLink }) => {
    const [progress, setProgress] = useState(0);
    const [paymentComplete, setPaymentComplete] = useState(false);
    const { user, isAuthenticated, isLoading } = useAuth0();
    const [payed, setPayed] = useRecoilState(payedState);


    const [isDownloading, setIsDownloading] = useState(false);
    useEffect(() => {
        if (downloadLink && window.paypal) {
            window.paypal.Buttons({
                createOrder: function (data, actions) {
                    return actions.order.create({
                        purchase_units: [{
                            amount: {
                                value: '1.00'
                            }
                        }]
                    });
                },
                onApprove: function (data, actions) {
                    return actions.order.capture().then(function (details) {
                        alert('Transaction completed by ' + details.payer.name.given_name);
                        setPaymentComplete(true);
                    });
                },
                style: {
                    color: 'blue',

                    shape: 'pill',

                    label: 'paypal'

                }
            }).render('#paypal-button-container');
        }
    }, [downloadLink]);

    const downloadVideo = async (videoUrl) => {
        try{
            setIsDownloading(true)
            const response = await fetch(videoUrl);
            const blob = await response.blob();
        
            // Extract the file extension from the URL
            const urlParts = videoUrl.split('.');
            const extension = urlParts[urlParts.length - 1];
        
            const blobURL = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobURL;
            
            // Use the extracted extension for the downloaded file
            link.setAttribute('download', `edit-videos-online.${extension}`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }finally{
            setIsDownloading(false);
        }
    };

  
    
    

    useEffect(() => {
        if (!isOpen) return;
        const startTime = new Date().getTime();
        const progressInterval = setInterval(() => {
            const elapsedTime = new Date().getTime() - startTime;
            const logProgress = Math.min(95, elapsedTime / (elapsedTime + estimatedTime * 300) * 100);
            setProgress(logProgress);
        }, 1000);
        return () => clearInterval(progressInterval);
    }, [isOpen, estimatedTime]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Exporting Modal"
            style={{
                overlay: { backgroundColor: 'rgba(0, 0, 0, 0.75)' , zIndex:1000},
                content: {

                   
                    borderRadius: '15px',

                    border: '1px solid #ffffff',
                    boxShadow: '0px 0px 10px 1px #0a8dff',
                    backgroundColor: '#181818',
                    color: '#ffffff',
                   

                },
        
            }}
        >
            <h2 style={{ color: "#45484B",   display: "flex",
                 flexDirection: "column",
                 alignContent: "center", alignItems:"center", marginBottom:"4vh" }}>Final step: Get your video!</h2>

            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", alignContent: "center" }}>
                {!downloadLink ? (
                    <div style={{ flex: 1 }}>
                        <h2>{title}</h2>
                        <ProgressBar now={progress} label={`${Math.round(progress)}%`} variant="info" style={{ width: '80%', margin: '30px 0' }} />
                    </div>
                ) : (

                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", flex: 0.6 }}>

                        {
                            downloadLink.endsWith('.mp4') || downloadLink.endsWith('.mov') ? (
                                <div>

                                    <video
                                        onContextMenu={(e) => e.preventDefault()}
                                        controls
                                        src={downloadLink}
                                        style={{ maxWidth: '100%', maxHeight: '300px' }}
                                    ></video>
                                </div>
                            ) : downloadLink.endsWith('.gif') ? (
                                <img
                                    src={downloadLink}
                                    alt="GIF content"
                                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                                    onContextMenu={(e) => e.preventDefault()}
                                />
                            ) : null
                        }
                    </div>
                )}

                <div style={{ flex: 0.4 }}>
                {downloadLink ? (
    isDownloading ?
        <Spinner animation="border" role="status"/>
        :
        <Button variant="primary" onClick={() => downloadVideo(downloadLink)}>Download Video</Button>
) : null}

                </div>
            </div>
     
        </Modal>

    );
}

export default CustomModal;

import React, { useState, useEffect } from 'react';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';

import { textListState, videoListState, scaleState, imageListState, canvasState, audioListState, payedState } from '../atoms'
import Modal from 'react-modal';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { BsDownload } from 'react-icons/bs'
import { FacebookShareButton, RedditShareButton, TwitterShareButton, WhatsappShareButton, FacebookIcon, RedditIcon, TwitterIcon, WhatsappIcon } from 'react-share';
import DownloadModal from './DownloadModal';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";

import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import { duration } from '@mui/material';
import AuthButtons from './AuthButtons';





function Export() {
  const [gif, setGif] = useState();
  const [videoList, setVideoList] = useRecoilState(videoListState);
  const [textList, setTextList] = useRecoilState(textListState);
  const [audioList, setAudioList] = useRecoilState(audioListState);

  const [imageList, setImageList] = useRecoilState(imageListState);

  const [progress, setProgress] = useState(0);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState(null)
  const [scale, setScale] = useRecoilState(scaleState);
  const [canvas, setCanvas] = useRecoilState(canvasState);
  const [payed, setPayed] = useRecoilState(payedState);


  const [selectedFormat, setSelectedFormat] = useState('mp4');

  const formats = ['mp4', 'gif', 'avi', 'mov', 'wmv', 'flv',];
  const [downloadLink, setDownloadLink] = useState(null);
  const { isAuthenticated, user } = useAuth0();

  const BASE_URL = process.env.REACT_APP_BACKEND_URL;

  

  useEffect(() => {
    if(isAuthenticated){
      axios.post(process.env.REACT_APP_BACKEND_URL + "/clientsubscription",  { email: user.email }, {
      })
        .then((res) => {
          if(res.data === "payed"){
            setPayed(true)
          }else{
            setPayed(false)
          }
        })
        
    }

    
}, [user])

  const handleSelect = (format) => {
    setSelectedFormat(format);
  };


  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setDownloadLink(null)
    setIsOpen(false);
  }

  function getFormDataSize(formData) {
    let blob = new Blob();

    formData.forEach(value => {
      if (value instanceof File) {
        blob = new Blob([blob, value], { type: 'text/plain' });
      } else {
        blob = new Blob([blob, new Blob([value])], { type: 'text/plain' });
      }
    });
    return blob.size;
  }








  async function getFileFromUrl(url, name, defaultType = 'video/mp4') {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: data.type || defaultType,
    });
  }

  async function getFilesFromUrls(urlList) {
    const filePromises = urlList.map(url => getFileFromUrl(url, url));
    return Promise.all(filePromises);
  }
  const delay = ms => new Promise(res => setTimeout(res, ms));
  const formData = new FormData();

  async function exportButton() {
    setDownloadLink(null)
    setIsOpen(true);

    const startTime = new Date().getTime();



    let aL = JSON.stringify(audioList)
    formData.append("audioList", aL)

    let vL = JSON.stringify(videoList)
    delete vL["previewImage"]
    formData.append("videoList", vL)

    formData.append("videoFormat", JSON.stringify(selectedFormat))

    let tL = JSON.stringify(textList)
    formData.append("textList", tL)

    let iL = JSON.stringify(imageList)
    formData.append("imageList", iL)

    formData.append("canvas", JSON.stringify(canvas))

    formData.append("scale", JSON.stringify(scale))

    const imageFiles = await getFilesFromUrls(imageList.map(item => item.src));
    imageFiles.forEach(file => formData.append("images", file));

    const audioFiles = await getFilesFromUrls(audioList.map(item => item.src));
    audioFiles.forEach(file => formData.append("audios", file));

    const videoFiles = await getFilesFromUrls(videoList.map(item => item.src));
    videoFiles.forEach(file => formData.append("videos", file));

    for (let i = 0; i < imageList.length; i++) {
      getFileFromUrl(imageList[i].src, imageList[i].src).then(value => {
        formData.append("images", value)
      })
    }

    for (let i = 0; i < audioList.length; i++) {
      getFileFromUrl(audioList[i].src, audioList[i].src).then(value => {
        formData.append("audios", value)
      })
    }


    let progressInterval = setInterval(() => {
      const elapsedTime = new Date().getTime() - startTime;
      // The divisor here (e.g., 20000) determines the speed of the progress bar.
      let logProgress = Math.min(95, elapsedTime / (elapsedTime + getFormDataSize(formData) / 2000) * 100);
      setProgress(logProgress);
    }, 1000);
    for (let i = 0; i < videoList.length; i++) {
      getFileFromUrl(videoList[i].src, videoList[i].src).then(value => {
        formData.append("videos", value);

        if (i === videoList.length - 1) {
          axios.post(process.env.REACT_APP_BACKEND_URL + "/export", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
            .then((res) => {
              clearInterval(progressInterval);
              // Assuming the server responds with a JSON object containing a videoLink property
              setDownloadLink(process.env.REACT_APP_BACKEND_URL + "/" + res.data);
            })
            .catch(function (error) {
              clearInterval(progressInterval);
              alert("Sorry, an error occurred; Try again later");
              // ... other error handling ...
            });
        }
      });
    }




  }

  return (

    <div>
      <DownloadModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        title="Export in Progress..."
        message="Unlike other platforms, we provide this service completely free of charge. If you appreciate our service, consider sharing our website on social media!"
        estimatedTime={20}
        downloadLink={downloadLink}
        style={{
          position:'absolute',
          zIndex:'101'
        }}
      />

      <div style={{ display: "flex" }}>
        <DropdownButton
          id="video-format-selector"
          title={selectedFormat || 'Select Video Format'}
          variant="success"
          onSelect={handleSelect}
          style={{ maxHeight: '8vh' }}
        >
          {formats.map((format) => (
            <Dropdown.Item key={format} eventKey={format}>
              {format}
            </Dropdown.Item>
          ))}
        </DropdownButton>{' '}

{/*isAuthenticated && */selectedFormat ? <Button style={{ maxHeight: '8vh' }} variant="success" onClick={exportButton}>
          Download Video
        </Button>:<AuthButtons></AuthButtons>}
      

      </div>
      {gif && <video src={gif} />}
    </div>
  )

}

export default Export;
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Button } from 'react-bootstrap';
import { FacebookShareButton, RedditShareButton, TwitterShareButton, WhatsappShareButton, FacebookIcon, RedditIcon, TwitterIcon, WhatsappIcon } from 'react-share';
import axios from 'axios';

const CustomModal = ({ isOpen, onRequestClose, url, title, message, estimatedTime, downloadLink }) => {
  const [progress, setProgress] = useState(0);
  const [paymentComplete, setPaymentComplete] = useState(false);

  useEffect(() => {
    if (downloadLink && window.paypal) {
      window.paypal.Buttons({
        createOrder: function (data, actions) {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: '1.00'
              }
            }]
          });
        },
        onApprove: function (data, actions) {
          return actions.order.capture().then(function (details) {
            alert('Transaction completed by ' + details.payer.name.given_name);
            setPaymentComplete(true);
          });
        },
        style: {      
          color:  'blue',
      
          shape:  'pill',
      
          label:  'paypal'
      
        }
      }).render('#paypal-button-container');
    }
  }, [downloadLink]);

  const downloadVideo = async (videoUrl) => {
    const response = await fetch(videoUrl);
    const blob = await response.blob();
    const blobURL = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobURL;
    link.setAttribute('download', 'edit-videos-online.com.mp4');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (!isOpen) return;
    const startTime = new Date().getTime();
    const progressInterval = setInterval(() => {
      const elapsedTime = new Date().getTime() - startTime;
      const logProgress = Math.min(95, elapsedTime / (elapsedTime + estimatedTime * 300) * 100);
      setProgress(logProgress);
    }, 1000);
    return () => clearInterval(progressInterval);
  }, [isOpen, estimatedTime]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Exporting Modal"
      style={{
        overlay: { backgroundColor: 'rgba(0, 0, 0, 0.75)' },
        content: {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          padding: '30px',
          width: '80%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '15px',
          border: '1px solid #ffffff',
          boxShadow: '0px 0px 10px 1px #0a8dff',
          backgroundColor: '#181818',
          color: '#ffffff',
        }
      }}
    >
      {!downloadLink ? (
        <>
          <h2>{title}</h2>
          <ProgressBar now={progress} label={`${Math.round(progress)}%`} variant="info" style={{ width: '80%', margin: '30px 0' }} />
          <p style={{ marginTop: '15px' }}>{message}</p>
        </>
      ) : (
        <>
          <div style={{ textAlign: 'center' }}>
            <img src="YOUR_LOGO_URL" alt="Your Video Editor Logo" style={{ position: 'absolute', top: '10px', right: '10px', width: '120px' }} />
            <video controls width="80%" src={downloadLink}></video>
            <h3>Your Video is Ready to Download</h3>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px', width: '80%', flexDirection: 'column' }}>
            {paymentComplete ?
              <Button variant="primary" onClick={() => downloadVideo(downloadLink)}>Download Video</Button>
              :
              <div id="paypal-button-container"></div>
            }
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <FacebookShareButton url={downloadLink} style={{ margin: '0 10px' }}><FacebookIcon size={32} round /></FacebookShareButton>
            <TwitterShareButton url={downloadLink} style={{ margin: '0 10px' }}><TwitterIcon size={32} round /></TwitterShareButton>
            <RedditShareButton url={downloadLink} style={{ margin: '0 10px' }}><RedditIcon size={32} round /></RedditShareButton>
            <WhatsappShareButton url={downloadLink} style={{ margin: '0 10px' }}><WhatsappIcon size={32} round /></WhatsappShareButton>
          </div>
        </>
      )}
    </Modal>
  );
}

export default CustomModal;


import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
  } from 'recoil';

  import React from 'react'


export const videoListState = atom({
    key: 'videoListState',
    default: [],
  });

  export const stackedVideoListState = atom({
    key: 'stackedVideoListState',
    default: [],
  });
  
  export const textListState = atom({
    key: 'textListState',
    default: [],
  });

  export const imageListState = atom({
    key: 'imageListState',
    default: [],
  });

  export const audioListState = atom({
    key: 'audioListState',
    default: [],
  });



  export const playState = atom({
    key: 'playState',
    default: false
  });

  export const timeState = atom({
    key: 'timeState',
    default: 1000
  })

  export const editState = atom({
    key: 'editState',
    default: "upload"
  })

  export const elementState = atom({
    key: 'elementState',
    default: 0
  })


  export const scaleState = atom({
    key: 'scaleState',
    default: 1
  })

  export const zoomState = atom({
    key: 'zoomState',
    default: 15000
  })

  export const zoomInState = atom({
    key: 'zoomInState',
    default: 0
  })

  export const zoomOutState = atom({
    key: 'zoomOutState',
    default: 0
  })

  export const scrollState = atom({
    key: 'scrollState',
    default: 0
  })

  export const subtitleDefaultXState = atom({
    key: 'subtitleDefaultXState',
    default: 0
  })

  export const subtitleDefaultYState = atom({
    key: 'subtitleDefaultYState',
    default: 0
  })

  export const subtitleDefaultSizeState = atom({
    key: 'subtitleDefaultSizeState',
    default: 20
  })

  export const subtitleDefaultColorState = atom({
    key: 'subtitleDefaultColorState',
    default: "#000000"
  })

  export const cursorUpdatedState = atom({
    key: 'cursorUpdatedState',
    default: "0"
  })

  export const canvasState = atom({
    key: 'canvasState',
    default: {width:540, height:960, color:"black"}
  })

  export const payedState = atom({
    key: 'payedState',
    default: false
  })


  
  
import React from 'react';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react'
import { fabric } from 'fabric'; // this also installed on your project
import { useEffect, useRef, useState, useContext } from 'react';
import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
} from 'recoil';
import { editState, elementState, textListState, imageListState, timeState, zoomState, canvasState, scaleState } from '../atoms';

import cloneDeep from 'lodash/cloneDeep';


function deepEqual(object1, object2) {
    return JSON.stringify(object1) === JSON.stringify(object2);
}
const Canvas = () => {
    const fabricRef = React.useRef(null);
    const canvasRef = React.useRef(null);
    const [canvas, setCanvas] = useRecoilState(canvasState)
    const [textList, _setTextList] = useRecoilState(textListState)
    const [imageList, _setImageList] = useRecoilState(imageListState)


    const textListRef = React.useRef(textList);
    const imageListRef = React.useRef(imageList);

    const [edit, setEdit] = useRecoilState(editState);
    const [element, setElement] = useRecoilState(elementState);

    const [time, setTime] = useState(0);


    const setTextList = data => {
        textListRef.current = data;
        _setTextList(data);
    };

    const setImageList = data => {
        imageListRef.current = data;
        _setImageList(data);
    };

    useEffect(() => {
        textListRef.current = cloneDeep(textList)
    }, [textList])

    useEffect(() => {
        imageListRef.current = cloneDeep(imageList)
    }, [imageList])

    useEffect(() => {
        let intervalId = null
        intervalId = setInterval(() => {
            let video = document.getElementById('timer')
            setTime(video.currentTime * 1000)
        }, 20);

        return () => clearInterval(intervalId);
    });

    useEffect(() => {
        for (let i = 0; i < textList.length; i++) {
            if ((time > textList[i].startTime && time < textList[i].endTime)) {
                if (textList[i].visible === false) {
                    let tL = cloneDeep(textList)
                    tL[i].visible = true
                    setTextList(tL)
                }
            } else {
                if (textList[i].visible === true) {
                    let tL = cloneDeep(textList)
                    tL[i].visible = false
                    setTextList(tL)
                }
            }
        }
        for (let i = 0; i < imageList.length; i++) {
            if ((time > imageList[i].startTime && time < imageList[i].endTime)) {
                if (imageList[i].visible === false) {
                    let iL = cloneDeep(imageList)
                    iL[i].visible = true
                    setImageList(iL)
                }
            } else {
                if (imageList[i].visible === true) {
                    let iL = cloneDeep(imageList)
                    iL[i].visible = false
                    setImageList(iL)
                }
            }
        }


    }, [time])


    function modifyImage(id, modifiedObject) {
        imageListRef.current[id].x = modifiedObject.get('left')
        imageListRef.current[id].y = modifiedObject.get('top')
        imageListRef.current[id].width = modifiedObject.get('width') * modifiedObject.get('scaleX')
        imageListRef.current[id].height = modifiedObject.get('height') * modifiedObject.get('scaleY')


        setImageList(imageListRef.current)
        setEdit("image")
        setElement(id)
    }

    function modifyText(id, modifiedObject) {
        let fontSize = modifiedObject.fontSize * modifiedObject.scaleX

        textListRef.current[id].x = modifiedObject.get('left')
        textListRef.current[id].y = modifiedObject.get('top')
        textListRef.current[id].size = fontSize
        textListRef.current[id].text = modifiedObject.text
        textListRef.current[id].width = modifiedObject.get('width') * modifiedObject.get('scaleX')
        textListRef.current[id].height = modifiedObject.get('height') * modifiedObject.get('scaleY')

        setTextList(textListRef.current)
        setEdit("text")
        setElement(id)
    }

    function modified(evt) {
        var modifiedObject = evt.target;

        let id = modifiedObject.id
        let type = modifiedObject.type

        if (type === "text") {
            modifyText(id, modifiedObject)
        }
        if (type === "image") {
            modifyImage(id, modifiedObject)
        }

    };



    useEffect(() => {
        const initFabric = () => {
            fabricRef.current = new fabric.Canvas(canvasRef.current);
            fabricRef.current.setWidth(1000);
            fabricRef.current.setHeight(1000);

            fabricRef.current.on({
                'object:modified': modified,
            });
        };
        initFabric();
    }, [])

    const addImages = async () => {
        await Promise.all(imageList.map(image => new Promise((resolve, reject) => {
            fabric.Image.fromURL(image.src, function (oImg) {
                oImg.set('id', image.id)
                oImg.set('left', image.x)
                oImg.set("top", image.y)
                oImg.scaleToWidth(image.width)
                oImg.scaleToHeight(image.height)
                oImg.set('visible',image.visible)

                fabricRef.current.add(oImg);
                oImg.sendToBack();
                resolve();
            });
        })));
    };

    const addTexts = () => {
        let tL = cloneDeep(textList);
        for (let i = 0; i < textList.length; i++) {

            const text = new fabric.IText(textList[i].text, {
                id: i,
                type: "text",
                fill: textList[i].color,
                fontSize: textList[i].size,
                fontFamily: 'KomikaTitle',
                editable: true,
                left: textList[i].x,
                top: textList[i].y,
                visible: textList[i].visible,
            })

            if (tL[i].height !== text.height || tL[i].width !== text.width) {
                tL[i].height = text.height;
                tL[i].width = text.width;
            }
            tL[i].height = text.height
            tL[i].width = text.width


            const bg = new fabric.Rect({
                left: textList[i].x,
                top: textList[i].y,
                fill: textList[i].backgroundColor,
                rx: textList[i].cornerRadius, // Set this to the desired corner radius
                ry: textList[i].cornerRadius,
                width: text.width,
                height: text.height,
                visible: textList[i].visible,
                fill: textList[i].backgroundColor
            });

            
            
            fabricRef.current.add(bg);
            fabricRef.current.add(text);

            bg.bringToFront();
            text.bringToFront();

           

        }
        if (!deepEqual(textList, tL)) {
            setTextList(tL);
        }
    };




    useEffect(() => {
        clearTextsFromCanvas();
        addTexts();
    }, [textList]);
    
    const clearTextsFromCanvas = () => {
        fabricRef.current.getObjects('text').forEach(text => {
            fabricRef.current.remove(text);
        });

        fabricRef.current.getObjects('rect').forEach(rect => {
            fabricRef.current.remove(rect);
        });

  
    };

    useEffect(() => {
        clearImagesFromCanvas();
        addImages();
    
    }, [imageList]);

    const clearImagesFromCanvas = () => {
        fabricRef.current.getObjects('image').forEach(image => {
            fabricRef.current.remove(image);
        });
  
    };

    return (
        <div style={{ position: "absolute", maxWidth: "100%", maxHeight: "100%" }}>
            <canvas ref={canvasRef}></canvas>

        </div>
    );
};

export default Canvas;
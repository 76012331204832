import React, { useState, useEffect } from 'react';
import { ProgressBar } from 'react-bootstrap';

const FakeProgress = ({ duration = 60 }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const startTime = Date.now();

    const updateProgress = () => {
      const elapsedTime = (Date.now() - startTime) / 1000; // Convert to seconds
      const progressPercentage = (1 - Math.exp(-elapsedTime / duration)) * 100;

      setProgress(progressPercentage);

      if (progressPercentage < 99.9) {
        requestAnimationFrame(updateProgress);
      }
    };

    requestAnimationFrame(updateProgress);

    return () => {
      setProgress(0); // Reset progress if the component unmounts
    };
  }, [duration]);

  return (
    <ProgressBar now={progress} label={`${progress.toFixed(2)}%`} />
  );
};

export default FakeProgress;

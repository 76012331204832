import { MdNotStarted } from 'react-icons/md';
import { BsFillPlayFill, BsFillPauseFill, BsZoomOut, BsZoomIn, BsSkipForwardCircle, BsSkipBackwardCircle, BsScissors } from 'react-icons/bs'
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import Button from 'react-bootstrap/Button';
import { zoomState, playState, timeState, videoListState, zoomInState, zoomOutState } from '../atoms';
import { GrBackTen, GrForwardTen, GrZoomOut, GrZoomIn } from "react-icons/gr";
import { TbArrowAutofitWidth } from "react-icons/tb";
import Export from './Export';
import { AccessAlarm, AttachEmail, ConstructionOutlined, ThreeDRotation, TimeToLeave, TimesOneMobiledata } from '@mui/icons-material';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { AppContext } from "../components/VideoEditorWrapper";
import { IoMdFastforward } from 'react-icons/io';
import Timer from '../../src/timer.mp4'
import cloneDeep from 'lodash/cloneDeep';
import axios from 'axios';
import JSZip from 'jszip';
import { Spinner, Modal, ProgressBar } from 'react-bootstrap';
import CustomModal from './CustomModal';
import { startTransition } from 'react';


function ControlBar() {
  const [zoom, setZoom] = useRecoilState(zoomState);
  const [play, setPlay] = useRecoilState(playState);
  const [internalTime, setInternalTime] = useState(0);
  const [videoList, setVideoList] = useRecoilState(videoListState);
  const [time, setTime] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [zoomIn, setZoomIn] = useRecoilState(zoomInState);
  const [zoomOut, setZoomOut] = useRecoilState(zoomOutState);

  const controlBarStyle = {
    position: 'relative',
    backgroundColor: "#141725",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: '1vh', // Adjust padding to be relative
  };

  const buttonStyle = {
    color: "#F7F7F7",
    justifyContent: "center",
    fontSize: '3vh', // Make font size responsive
    margin: '1vh', // Adjust margin to be relative
  };

  // Responsive design adjustments with media queries
  const mediaQueryStyles = `
    @media (max-width: 600px) {
      .control-bar {
        padding: 1vw;
      }
      .button {
        font-size: 4vw;
        padding: 2vw;
      }
      .time-display {
        font-size: 2vw;
      }
    }
  `;




  useEffect(() => {
    let intervalId = null
    intervalId = setInterval(() => {
      let video = document.getElementById('timer')
      setTime(video.currentTime * 1000)
    }, 20);

    return () => clearInterval(intervalId);
  });

  useEffect(() => {
    let video = document.getElementById('timer')
    if (play === true) {
      video.play()
    }
    if (play === false) {
      video.pause()
    }

  }, [play])

  function changeZoom(e) {
    //setZoom(e)
  }

  function playpause() {
    setPlay(!play)
  }



  function millisecondsToTime(s) {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;

    return hrs + ':' + mins + ':' + secs;
  }







  function autoFit() {
    setZoom(videoList[videoList.length - 1].endTime)
  }

  const handleClose = () => {
    setShowModal(false);
  };


  async function getFileFromUrl(url, name, defaultType = 'video/mp4') {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: data.type || defaultType,
    });
  }


  useEffect(() => {
  }, [videoList])


  async function setVideo(part1, part2) {
    /* var video = document.createElement('video');
     video.src = URL.createObjectURL(e)
   
     function setDuration() {
       let duration = video.duration * 1000
       let vl = cloneDeep(videoList)
   
       let starttime = 0
       if (vl.length > 0) {
         starttime = vl[vl.length - 1].endTime
       }

   
       vl.push({ 
         id: vl.length, 
         "src": URL.createObjectURL(e), 
         file: e, 
         duration: duration, 
         "x": null, 
         "y": null, 
         startTime: starttime, 
         endTime: starttime + duration, 
         trimmStart: 0.0, 
         trimmEnd: 0.0 
       })
   
       setVideoList(vl)
       setZoom(10000)
     }
   
     video.addEventListener("loadedmetadata", setDuration);*/

    let currentVideoId = 0
    for (let i = 0; i < videoList.length; i++) {
      if (videoList[i].startTime < time && videoList[i].endTime > time) {
        currentVideoId = i;
      }
    }


    var part1video = document.createElement('video');
    part1video.src = URL.createObjectURL(part1)

    var part2video = document.createElement('video');
    part2video.src = URL.createObjectURL(part2)


    let vl = []

    function setDuration(p) {
      return new Promise((resolve) => {
        let duration = p.duration * 1000


        let starttime = videoList[videoList.length - 1].startTime
        if (vl.length > 0) {
          starttime = vl[vl.length - 1].endTime
        }


        vl.push({
          id: currentVideoId + vl.length,
          "src": p.src,
          file: p,
          duration: duration,
          "x": null,
          "y": null,
          startTime: starttime,
          endTime: starttime + duration,
          trimmStart: 0.0,
          trimmEnd: 0.0
        })

        resolve();
      });
    }
    let newVideoList = cloneDeep(videoList)

    async function handleLoadedMetadata(partVideo) {
      return new Promise((resolve) => {
        partVideo.addEventListener("loadedmetadata", async () => {
          await setDuration(partVideo);
          resolve();
        });
      });
    }

    await handleLoadedMetadata(part1video);
    await handleLoadedMetadata(part2video);


    newVideoList.splice(currentVideoId, 1, vl[0], vl[1])

    setVideoList(newVideoList)

  }

  function getCurrentVideoId() {
    for (let i = 0; i < videoList.length; i++) {
      if (videoList[i].startTime < time && videoList[i].endTime > time) {
        return i;
      }
    }
    return null;
  }

  function findStartBySrc(src) {
    // Assuming videoList is accessible in the scope of this function
    const video = videoList.find(video => video.src === src);

    // If a video was found, return its startTime property; otherwise, return null
    return video ? video.startTime : null;
  }

  function cut() {
    if (time > 0) {
      let vl = cloneDeep(videoList);
      let videoid = getCurrentVideoId();

      let clonedObject = cloneDeep(vl[videoid]);


      //old object
      vl[videoid].trimmEnd = vl[videoid].endTime - time
      vl[videoid].endTime = time


      let offset = findStartBySrc(clonedObject.src)
      //new object
      clonedObject.startTime = time
      clonedObject.trimmStart = time - offset;

      vl.splice(videoid + 1, 0, clonedObject);

      vl.forEach((item, index) => {
        item.id = index;
      });

      setVideoList(vl)
    }

  }










  return (
    <div style={{       backgroundColor: "#141725", borderRadius: "10px",     height: "8vh", display: "flex", alignItems: "center", justifyContent:"space-between"}}>
      <CustomModal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        title="Splitting Video"
        message="This function splits your video at the current position."
        estimatedTime={60}
      />

      <video style={{ display: "none" }} styleautoPlay id="timer" src={Timer}></video>

<div>
      {videoList[videoList.length - 1] !== undefined ? <h6 style={{ color: "white" }}>{millisecondsToTime(time)} /  {millisecondsToTime(videoList[videoList.length - 1].endTime)}</h6> : null}
      </div>

      {/*<Slider waria-label="Volume" value={zoom} onChange={e => changeZoom(e.target.value)} onChangeCommitted={() => resetTimeline()} min={1000} max={100000} style = {{margin:"1.5vh", width:"30%"}}/>*/}

<div>
      <Button style={{ color: "white" }} variant="muted" onClick={() => playpause()}>
        {play ? <BsFillPauseFill /> : <BsFillPlayFill />}
      </Button>
      <Button style={{ color: "white" }} id="zoomIn" variant="muted" onClick={() => { setZoomOut(zoomOut + 1) }}><BsZoomOut /></Button>{' '}
      <Button style={{ color: "white" }} variant="muted" onClick={() => { setZoomIn(zoomIn + 1) }}><BsZoomIn /></Button>{' '}
      {/*<Button style={{color:"white"}}variant="muted" onClick={() => { autoFit() }}><TbArrowAutofitWidth /></Button>{' '}*/}
      <Button style={{ color: "white" }} variant="muted" onClick={() => { cut() }}>

        <BsScissors />

      </Button>
      </div>


      <Export />




    </div>
  );
}

export default ControlBar;
import { Avatar } from '@mui/material';
import Modal from 'react-modal';
import React, { useState, useEffect } from 'react';
import { Button, Container, Card, Form, Spinner } from 'react-bootstrap';
import axios from "axios";


Modal.setAppElement('#root'); // Assuming your root element has the ID 'root'

function AvatarModal({ isOpen, onRequestClose }) {

    const [selectedVideo, setSelectedVideo] = useState(null);
    const videoNames = ['video1.mp4', 'video2.mp4']
    const [selectedVoice, setSelectedVoice] = useState(7);  // Default is voice 1
    const [text, setText] = useState("");
    const [isGeneratingVoice, setIsGeneratingVoice] = useState(false);
    const [mp3, setmp3] = useState(null);
    const [generatedAvatar, setGeneratedAvatar] = useState(null);
    const [paymentComplete, setPaymentComplete] = useState(null);
    const [isGenerating, setIsGenerating] = useState(null);

    const urlToBlob = async (url) => {
        return await fetch(url).then(res => res.blob());
    };

    const sendMP3 = async (mp3Url) => {
        try {
            const mp3Blob = await urlToBlob(mp3Url);
    
            // Create a FormData object and append the file
            const formData = new FormData();
            formData.append('mp3', mp3Blob, 'audio.mp3');
            formData.append('selectedVideo', selectedVideo);
            setIsGenerating(true);
    
            // Send the request
            const response = await axios.post(process.env.REACT_APP_BACKEND_URL + "/createavatar", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                setGeneratedAvatar(response.data);
                setIsGenerating(false)
            })
            .catch(error => {
                // Handle error
               alert("Error in the request:", error);
            });
            
        } catch (error) {
            console.error('Error sending file', error);
        }
    };
    


    const generateAvatar= () => {
        sendMP3(mp3)
    }


    const generateVoice = () => {
        setIsGeneratingVoice(true);

        axios
            .post(process.env.REACT_APP_BACKEND_URL + "/tts", {

                text,
                voice: selectedVoice,
            }, {
                responseType: 'blob'
            }

            )
            .then((response) => {
                const url = URL.createObjectURL(response.data);
                setmp3(url);
                setIsGeneratingVoice(false);
            })
            .catch((error) => {
                console.error("Error generating voice:", error);
                setIsGeneratingVoice(false);
            });
    };

    const videoAvatarStyle = (isSelected) => ({
        cursor: 'pointer',
        border: isSelected ? '2px solid darkgray' : '2px solid transparent',
        borderRadius: "2vh",
        width: '128px',
        height: '128px',
        display: 'inline-block',
    });

    const handleSelect = (videoName) => {
        setSelectedVideo(videoName);
    };

    const avatarSelectionStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
    };

    useEffect(() => {
        if (mp3 && window.paypal) {
          window.paypal.Buttons({
            createOrder: function (data, actions) {
              return actions.order.create({
                purchase_units: [{
                  amount: {
                    value: '1.00'
                  }
                }]
              });
            },
            onApprove: function (data, actions) {
              return actions.order.capture().then(function (details) {
                generateAvatar();

              });
            },
            style: {      
              color:  'blue',
          
              shape:  'pill',
          
              label:  'paypal'
          
            }
          }).render('#paypal-button-container');
        }
      }, [mp3]);



    return (
        <div style={{zIndex: 10000}}>
        <Modal

            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={{
                zIndex: 10000,
                content: {

                    borderRadius: '15px',
                    border: '1px solid #ffffff',
                    boxShadow: '0px 0px 10px 1px #0a8dff',
                    backgroundColor: '#181818',
                    color: '#ffffff',
                    zIndex: 10000
                },
               
            }}
        >
            <div style={{zIndex: 10000}}>

            <h3>1. Choose an avatar</h3>
            <div style={avatarSelectionStyle}>
                {videoNames.map((videoName, index) => (
                    <div
                        key={index}
                        style={videoAvatarStyle(selectedVideo === videoName)}
                        onClick={() => handleSelect(videoName)}
                    >

                        <video
                            src={`videos/${videoName}`}
                            loop
                            autoPlay
                            muted
                            style={{ width: '100%', height: 'auto', borderRadius: "2vh", }}
                        />
                    </div>
                ))}
            </div>


{selectedVideo? <div style ={{marginTop:"2vh"}}>
            <h3>2. Generate a voice</h3>

<Form >
                <Form.Group controlId="formTextInput">
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        placeholder="Enter your text you want to generate audio for"
                    />
                </Form.Group>
                <div style ={{display:"flex"}}>

                <Form.Group controlId="voiceSelection">
                    <Form.Select
                        value={selectedVoice}
                        onChange={(e) => setSelectedVoice(Number(e.target.value))}>
                        {
                            [...Array(109).keys()].map(i => (
                                <option key={i + 1} value={i + 1}>Voice {i + 1}</option>
                            ))
                        }
                    </Form.Select>
                </Form.Group>



                <Button variant="primary" onClick={() => generateVoice()}>
                    {isGeneratingVoice ? <Spinner animation="border" size="sm" className="mr-2" /> : null}
                    Generate Voice
                </Button>
                </div>

               
            </Form></div>:null}
           
            {mp3 ? 
            <div style={{display:"flex", flexDirection: "column", maxWidth: "30%", marginTop:"2vh"}}>
                            <h3>3. Check the generated Voice</h3>
                <audio controls src={mp3}>
                    Your browser does not support the audio element.
                </audio> 
                {generatedAvatar ?  <video style={{marginTop:"2vh"}} controls src={generatedAvatar}></video> :
                
                <div style={{marginTop:"2vh"}}>
                    <h3>4. Generate your Avatar</h3>
                   { !isGenerating ? 
                   
             <Button variant="primary" onClick={() => generateAvatar()}>

                    Generate Avatar
                </Button>
: <div>  
<h5>This takes up to 8 minutes. Do NOT close this window</h5>
<Spinner animation="border" role="status">
<span className="visually-hidden">Loading...</span>
</Spinner></div>}
                    {/*!isGenerating ?   <div style={{marginTop:"2vh"}}>
                                    <h2 style={{ color: "#45484B" }}>Your avatar is ready for generating</h2>
                                    <h4 style={{ color: "#45484B" }}>0.99$ - Rendered avatar</h4>
                                    <div style={{ width: "10vw" }} id="paypal-button-container"></div>
                                </div>:  
                                <div>  
                                <h5>This takes up to 8 minutes. Do NOT close this window</h5>
                                <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
            </Spinner></div>*/}
                    </div>
                }
                
              
                               
            </div>
                :  null}



</div>
        </Modal>
        </div>
    );
}
export default AvatarModal;  
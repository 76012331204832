import Button from 'react-bootstrap/Button';
import { videoListState, textListState, subtitleDefaultXState, subtitleDefaultYState, subtitleDefaultSizeState, subtitleDefaultColorState } from '../atoms';
import { useEffect, useRef, useState } from 'react';
import { HexColorPicker } from "react-colorful";

import axios from "axios";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';

import {ProgressBar, Row, Col} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import cloneDeep from 'lodash/cloneDeep';
import { SketchPicker } from 'react-color';








const Subtitles = () => {
  const [textList, setTextList] = useRecoilState(textListState);
  const [subtitleDefaultX, setSubtitleDefaultX] = useRecoilState(subtitleDefaultXState);
  const [subtitleDefaultY, setSubtitleDefaultY] = useRecoilState(subtitleDefaultYState);
  const [subtitleDefaultSize, setSubtitleDefaultSize] = useRecoilState(subtitleDefaultSizeState);
  const [subtitleDefaultColor, setSubtitleDefaultColor] = useRecoilState(subtitleDefaultColorState);
  const [wordCount, setWordCount] = useState(3);

  const [showTextColorPicker, setShowTextColorPicker] = useState(false);
  const [showBackgroundColorPicker, setShowBackgroundColorPicker] = useState(false);

  const [videoList, setVideoList] = useRecoilState(videoListState);
  const [progress, setProgress] = useState(0);
  const [isGenerating, setIsGenerating] = useState(false)
  const [showDefault, setShowDefault] = useState(false)

  
  const [backgroundColor, setBackgroundColor] = useState({
    r: '248',
    g: '231',
    b: '28',
    a: '0.8',
  });


  const handleTextSizeChange = (e) => {
    const newSize = parseInt(e.target.value);
    setSubtitleDefaultSize(newSize);
  
    let newTextList = cloneDeep(textList); // clone the current textList

    let videoElement = document.getElementById("video");

    // Get the real dimensions of the video
    let videoWidth = videoElement.clientWidth;
    let videoHeight = videoElement.clientHeight;
  
    newTextList.forEach((subtitle, index) => {
      if (subtitle.tag === "subtitle") {
        // Calculate the new width using an approximate character width
        // This is a rough estimate and assumes monospace characters
        
        // Center the subtitle based on the new text width
        // Assuming subtitleDefaultX is the center of your video/player
        const newX =  videoWidth/2 - subtitle.width / 2;
        const newY =  videoHeight * 0.95 - subtitle.height

  
        newTextList[index] = {
          ...subtitle,
          x: newX,
          y: newY,
          size: newSize,
          // Recalculate other properties if needed
        };
      }
    });
  
    setTextList(newTextList); // Update the textList state with the new positions and sizes
  };
  

  const renderSubtitles = () => {
    return textList.map((subtitle, index) => {
      if (subtitle.tag === "subtitle") {
        return (
          <Form.Group key={index}>
            <Form.Label>
            </Form.Label>
            <Col >
              <Form.Control
                type="text"
                value={subtitle.text}
                onChange={(e) => {
                  let newTextList = cloneDeep(textList); // Continue using cloneDeep
                  newTextList[index].text = e.target.value;
                  setTextList(newTextList);
                }}
              />
            </Col>
          </Form.Group>
        );
      }
      return null;
    });
  };
  
  
  




  useEffect(() => {
    if (isGenerating) {
      const interval = setInterval(() => {
        if (progress < 92) {
          setProgress(progress + 1)
        } else {
          setProgress(10)
        }
      }, 100 * videoList[0].duration / 60000);
      return () => clearInterval(interval);
    }
  })

  useEffect(() => {
    let tl = cloneDeep(textList)
    for (let i = 0; i < textList.length; i++) {
      if (tl[i].tag === "subtitle") {
        tl[i].size = subtitleDefaultSize;
        tl[i].color = subtitleDefaultColor;
        const rgbaColor = `rgba(${backgroundColor.r},${backgroundColor.g},${backgroundColor.b},${backgroundColor.a})`
        tl[i].backgroundColor = rgbaColor
      }

    }

    setTextList(tl);
  }, [subtitleDefaultSize, subtitleDefaultColor, backgroundColor])


  function mergeWords(arr, wordcount) {
    const result = [];

    for (let i = 0; i < arr.length; i += wordcount) {
      let wordsToMerge = arr.slice(i, i + wordcount);

      let merged = {
        word: wordsToMerge.map(w => w.text).join(' '),
        start: wordsToMerge[0].start,
        end: wordsToMerge[wordsToMerge.length - 1].end
      };

      result.push(merged);
    }

    return result;
  }


  function addSubtitlesToVideo(data) {
    let newArr = [...textList]

    let videoElement = document.getElementById("video");

    // Get the real dimensions of the video
    let videoWidth = videoElement.clientWidth;
    let videoHeight = videoElement.clientHeight;

    let centerX = videoWidth / 2; // Center on the X-axis
    let centerY = videoHeight - videoHeight*0.1; // Near the bottom

    const avgCharWidth = 10; // Rough estimate of character width in pixels

    for (let i = 0; i < data.length; i++) {
      let text = data[i].word;
      
      // Calculate approximate text width
      let textWidth = text.length * avgCharWidth;

      newArr.push({ 
          "id": textList.length + i, 
          group: 1, 
          tag: "subtitle", 
          "text": " " + text + " ", 
          "x": centerX - textWidth/2, // Adjust x position to center the text 
          "y": centerY, 
          startTime: data[i].start * 1000, 
          endTime: data[i].end * 1000, 
          color:"#000000", 
          backgroundColor:'rgba(248,231,28,0.8)', 
          size: 20, 
          visible: false, 
          font: "Arial",
          cornerRadius:10
      })
    }

    setTextList(newArr);
}



  function changebackgroundcolor(color) {
    setBackgroundColor({ r: color.rgb.r, g: color.rgb.g, b: color.rgb.b, a: color.rgb.a })

    //newArr[element].backgroundColor = rgbaColor
    //setTextList(newArr);
  }

  function generateSubtitles() {
    setIsGenerating(true)
    let form = new FormData();
    form.append("file", videoList[0].file);

    axios.post(process.env.REACT_APP_BACKEND_URL + "/subtitles", form).then(response => {
      setIsGenerating(false)
      setProgress(0)
      addSubtitlesToVideo(mergeWords(response.data, wordCount))
      setShowDefault(true)
    })
  }

  return (
    <div style={{ color: "#F7F7F7", padding: "2vh" }}>
      {isGenerating ? (
        <>
          <h3 style={{ color: "white" }}>Generating Subtitles...</h3>
          <ProgressBar now={progress} animated striped variant="info" />
        </>
      ) : !showDefault && ( // Only show the generate button if subtitles aren't already generated
      <div>
      <Form.Label>Words per Subtitle Block</Form.Label>
      <Form.Control
        value={wordCount}
        type="number"
        onChange={e => setWordCount(parseInt(e.target.value, 10) || 3)}
      />
      <Button onClick={generateSubtitles}>Generate Subtitles</Button>
    </div>
      )}
  
      {showDefault && (
        <>
          <Form.Group className="my-3">
            <Form.Label>Text Size</Form.Label>
            <br/>
            <input
              type="range"
              value={subtitleDefaultSize}
              min="10"
              max="50"
              onChange={handleTextSizeChange}
/>
              </Form.Group>
  
          <Form.Group className="my-3">
            <Form.Label>Text Color</Form.Label>
            <div
              style={{
                width: "36px",
                height: "36px",
                borderRadius: "50%",
                backgroundColor: subtitleDefaultColor,
                cursor: "pointer"
              }}
              onClick={() => setShowTextColorPicker(!showTextColorPicker)}
            />
            {showTextColorPicker && (
              <div style={{ position: 'absolute', zIndex: '2' }}>
                <HexColorPicker
                  color={subtitleDefaultColor}
                  onChange={(color) => setSubtitleDefaultColor(color)}
                />
              </div>
            )}
          </Form.Group>
  
          <Form.Group className="my-3">
            <Form.Label>Background Color</Form.Label>
            <div
              style={{
                width: "36px",
                height: "36px",
                borderRadius: "50%",
                backgroundColor: `rgba(${backgroundColor.r},${backgroundColor.g},${backgroundColor.b},${backgroundColor.a})`,
                cursor: "pointer"
              }}
              onClick={() => setShowBackgroundColorPicker(!showBackgroundColorPicker)}
            />
            {showBackgroundColorPicker && (
              <div style={{ position: 'absolute', zIndex: '2' }}>
                <SketchPicker
                  color={backgroundColor}
                  onChange={(color) => changebackgroundcolor(color)}
                />
              </div>
            )}
          </Form.Group>
  
          {renderSubtitles()}
        </>
      )}
    </div>
  );
  
}

export default Subtitles;